/* eslint-disable */
import swal from 'sweetalert2'
import qs from 'qs'

import { fetcher } from '../utils/fetcher'
import { resetFilters, updateFilters } from './parcels/slice.parcels'
import i18n from '../lang/i18n'
import store from './store'

const constructRequest = async (
  dispatch,
  actionName,
  urlPath,
  method,
  obj = {},
  options = {}
  // eslint-disable-next-line consistent-return
) => {
  const { t } = i18n
  dispatch({
    type: 'LOADING',
    action: actionName,
  })

  const state = store.getState()
  try {
    const opt = { ...options, method }
    if (state.root.carrierHeader) {
      opt.headers = {
        ...options.headers,
        'x-iboxen-carrier': state.root.carrierHeader,
      }
    }
    let query = urlPath.split('?')[1] || ''
    urlPath = urlPath.split('?')[0]
    const isGET = method.toUpperCase() === 'GET'
    if (!isGET) {
      opt.body = JSON.stringify(obj)
    } else {
      query = `?${qs.stringify({
        ...qs.parse(query),
        ...obj,
      })}`
    }
    const { error, response } = await fetcher(
      `${encodeURI(urlPath)}${query}`,
      opt
    )

    dispatch({
      type: `${actionName}_${error ? 'FAILED' : 'SUCCESS'}`,
      payload: error || response,
    })

    if (error) {
      let message = error
      const isInvalidFormatError =
        error.includes('Invalid format') ||
        error.includes('Incorrect format for:')
      const isInvalidPhoneError = error.includes('Invalid phone number')
      const isRequiredError = error.includes('is required')
      const responseErrorExists = i18n.exists(
        `responseMessages:${error.replaceAll(' ', '-')}`
      )
      const parcelErrorExists = i18n.exists(
        `parcels:errors.${error.replaceAll(' ', '-')}`
      )
      if (isInvalidFormatError) {
        message = t('responseMessages:Invalid-format', {
          field: error.split('for:')[1],
        })
      }
      if (isInvalidPhoneError) {
        message = t('parcels:errors.Felaktigt-telefonnummer')
      }
      if (isRequiredError) {
        message = t('responseMessages:Is-required', {
          field: error.split(' is required')[0],
        })
      }
      if (!isInvalidFormatError && responseErrorExists) {
        message = t(`responseMessages:${error.replaceAll(' ', '-')}`)
      }
      if (!isInvalidFormatError && !responseErrorExists && parcelErrorExists) {
        message = t(`parcels:errors.${error.replaceAll(' ', '-')}`)
      }
      if (
        !isInvalidFormatError &&
        !responseErrorExists &&
        !parcelErrorExists &&
        !isRequiredError &&
        !isInvalidPhoneError
      ) {
        message = message = t('responseMessages:problem-fallback', {
          error: error,
        })
      }
      swal.fire({
        position: 'center',
        icon: 'error',
        title: message,
        showConfirmButton: true,
      })
    }

    if (response && response.responseText) {
      let title = t(
        `responseMessages:${response.responseText.replaceAll(' ', '-')}`
      )
      if (response.responseText.includes('bokad i leveransbox')) {
        const box = response.responseText.split(' ')[1]
        const address = response.responseText.split('.')[1]
        title = t(`responseMessages:bokad-i-leveransbox`, {
          box,
          address,
        })
      }
      console.log('Response Text:', response.responseText)
      swal.fire({
        position: 'center',
        icon: 'success',
        title,
        showConfirmButton: false,
        timer: 1500,
      })
    }

    return { error, response }
  } catch (e) {
    if (e.name !== 'AbortError') {
      swal.fire({
        position: 'center',
        icon: 'error',
        title: t(`responseMessages:problem}`),
        showConfirmButton: true,
      })
    }
  }
}

export const getMe = () => async (dispatch) =>
  constructRequest(dispatch, 'getMe', '/me', 'GET')

export const getCarrier = () => async (dispatch) =>
  constructRequest(dispatch, 'getCarrier', '/carrier', 'GET')
  
export const changeCarrierPassword = (input) => async (dispatch) =>
  constructRequest(
    dispatch,
    'changeCarrierPassword',
    '/carrier/password',
    'PUT',
    input
  )


export const saveSettings = (settingName, obj) => async (dispatch) => {
  return constructRequest(
    dispatch,
    'saveSettings',
    `/carrier/settings/${settingName}`,
    'PUT',
    obj
  )
}
export const getBoxTypes = (locationId) => async (dispatch) => {
  return constructRequest(
    dispatch,
    'getBoxTypes',
    `/carrier/location/boxTypes/${locationId}`,
    'GET'
  )
}
export const getBoxes = (boxIds) => async (dispatch) => {
  return constructRequest(
    dispatch,
    'getBoxes',
    `/carrier/boxes?boxIds=${boxIds.join(',')}`,
    'GET'
  )
}

export const getSettings = () => async (dispatch) => {
  return constructRequest(dispatch, 'getSettings', '/carrier/settings', 'GET')
}

export const getLocation = (locationId) => async (dispatch) => {
  return constructRequest(
    dispatch,
    'getLocation',
    `/carrier/location/${locationId}`,
    'GET'
  )
}

export const triggerSendSMS = (parcelId) => async (dispatch) => {
  return constructRequest(
    dispatch,
    'triggerSms',
    `/carrier/trigger/sms/${parcelId}`,
    'POST'
  )
}

export const createParcel = (obj) => async (dispatch) => {
  return constructRequest(
    dispatch,
    'createParcel',
    '/carrier/parcels',
    'POST',
    obj
  )
}

export const createLocation = (obj) => async (dispatch) => {
  return constructRequest(
    dispatch,
    'createLocation',
    '/admin/location',
    'POST',
    obj
  )
}

export const updateLocation = (id, input) => async (dispatch) => {
  return constructRequest(
    dispatch,
    'updateLocation',
    `/carrier/location/${id}`,
    'PUT',
    input
  )
}

export const getParcelTraceLogs = (parcelId) => async (dispatch) => {
  return constructRequest(
    dispatch,
    'getParcelTraceLogs',
    `/carrier/parcels/${parcelId}/tracelogs`,
    'GET'
  )
}
export const getParcelStatusData = (parcelId) => async (dispatch) => {
  return constructRequest(
    dispatch,
    'getParcelStatusData',
    `/carrier/parcels/${parcelId}/statuses`,
    'GET'
  )
}

export const getDrivers = () => async (dispatch) => {
  return constructRequest(dispatch, 'getDrivers', `/carrier/drivers`, 'GET')
}
export const createDriver = (input) => async (dispatch) => {
  return constructRequest(
    dispatch,
    'createDriver',
    `/carrier/drivers`,
    'POST',
    input
  )
}
export const updateDriver = (input) => async (dispatch) => {
  return constructRequest(
    dispatch,
    'updateDriver',
    `/carrier/drivers`,
    'PUT',
    input
  )
}
export const deleteDriver = (driverId) => async (dispatch) => {
  return constructRequest(
    dispatch,
    'deleteDriver',
    `/carrier/drivers/${driverId}`,
    'DELETE'
  )
}

export const getLocationsByAddress =
  ({ street, zip, city }) =>
  async (dispatch) => {
    return constructRequest(
      dispatch,
      'getLocationsByAddress',
      `/carrier/locations/search?street=${street}&zip=${zip}&city=${city}`,
      'GET'
    )
  }

export const getLocations = () => async (dispatch) => {
  return constructRequest(
    dispatch,
    'getLocations',
    `/carrier/locations/all`,
    'GET'
  )
}

export const getAgentLocations = () => async (dispatch) => {
  return constructRequest(
    dispatch,
    'getAgentLocations',
    `/carrier/locations/agent`,
    'GET'
  )
}

export const updateLocations = (input) => async (dispatch) => {
  return constructRequest(
    dispatch,
    'getLocations',
    `/carrier/locations`,
    'PUT',
    input
  )
}

export const getParcelsExport = (period) => async (dispatch) => {
  return constructRequest(
    dispatch,
    'getParcelsExport',
    `/carrier/export/parcels?period=${period}`,
    'GET'
  )
}

export const getLocationsExport = () => async (dispatch) => {
  return constructRequest(
    dispatch,
    'getLocationsExport',
    `/carrier/export/locations`,
    'GET'
  )
}

export const updateParcel = (id, obj) => async (dispatch) => {
  return constructRequest(
    dispatch,
    'updateParcel',
    `/carrier/parcels/${id}`,
    'PATCH',
    obj
  )
}

export const setLegacyView = (legacyView) => (dispatch) => {
  dispatch(resetFilters())
  dispatch({ type: 'setLegacyView', payload: legacyView })
  if (legacyView) {
    dispatch(
      updateFilters({
        flow: localStorage.getItem('parcelFlow') || 'delivery',
      })
    )
  }
}
