import { createAsyncThunk } from '@reduxjs/toolkit'
import i18n from '../../lang/i18n'
import swaler from '../../utils/swaler'
import request from '../../utils/fetcher'

let ac
export default createAsyncThunk('getParcel', async ({ id }, thunkApi) => {
  if (ac) {
    try {
      ac.abort()
    } catch {
      // Silent
    }
  }
  try {
    ac = new AbortController()
    const state = thunkApi.getState()
    return await request(
      'GET',
      `/carrier/parcels/${id}`,
      {},
      {
        signal: ac.signal,
        headers: { 'x-iboxen-carrier': state.root.carrierHeader },
      }
    )
  } catch (e) {
    if (e.name !== 'AbortError') {
      let { message } = e
      if (i18n.exists(`responseMessages:${message.replaceAll(' ', '-')}`)) {
        message = i18n.t(`responseMessages:${message.replaceAll(' ', '-')}`)
      }
      swaler.error(message)
    }
    throw e
  } finally {
    ac = undefined
  }
})
