import React, { useMemo } from 'react'
import { useTable, useFilters, usePagination, useSortBy } from 'react-table'
import { Table } from 'react-bootstrap'

import filters from './Filters'
import TablePagination from './Pagination'
import SortableColumn from './SortableColumn'

export default function DataTable({
  columns: _columns,
  data,
  getRowId,
  pageSize = 10,
}) {
  const filterTypes = useMemo(
    () => ({
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id]
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true
        })
      },
      includes: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id]
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .includes(String(filterValue).toLowerCase())
            : true
        })
      },
      advancedNumber: (rows, id, filterValue) => {
        if (!filterValue) {
          return rows
        }
        const operand = filterValue.substr(0, 1)
        const value = Number(filterValue.substr(1))
        return rows.filter((row) => {
          const rowValue = Number(row.values[id] || '0')
          switch (operand) {
            case '<':
              return rowValue < value
            case '>':
              return rowValue > value
            default:
              return rowValue === value
          }
        })
      },
    }),
    []
  )

  const columns = useMemo(
    () =>
      _columns.map((column) => ({
        ...column,
        Filter:
          typeof column.Filter === 'string'
            ? filters[column.Filter] || null
            : column.Filter,
      })),
    [_columns]
  )

  const table = useTable(
    {
      columns,
      data,
      filterTypes,
      initialState: { pageSize },
      getRowId,
      autoResetPage: false,
      autoResetExpanded: false,
      autoResetGroupBy: false,
      autoResetSelectedRows: false,
      autoResetSortBy: false,
      autoResetFilters: false,
      autoResetRowState: false,
    },
    useFilters,
    useSortBy,
    usePagination
  )

  return (
    <>
      <Table striped bordered {...table.getTableProps()}>
        <thead>
          {table.headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps({ style: { width: column.width } })}
                >
                  <SortableColumn column={column}>
                    {column.render('Header')}
                  </SortableColumn>
                  {column.canFilter && (
                    <div style={{ marginTop: 10 }}>
                      {column.render('Filter')}
                    </div>
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...table.getTableBodyProps()}>
          {table.page.map((row) => {
            table.prepareRow(row)
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                })}
              </tr>
            )
          })}
        </tbody>
      </Table>
      <TablePagination table={table} />
    </>
  )
}
