import React, { useState } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import Modal from 'react-modal'
import { useDispatch } from 'react-redux'
import XLSX from 'xlsx'
import { useTranslation } from 'react-i18next'
import CloseModalButton from './CloseModalButton'
import { getParcelsExport, getLocationsExport } from '../redux/actions'

const ignoredParcelKeys = '_id'.split(' ')
const ignoredLocationsKeys = ''.split('')

function ExportModal({ carrier, closeModal }) {
  const dispatch = useDispatch()
  const [exportPeriod, setExportPeriod] = useState(1)
  const { t } = useTranslation('common')

  const createAndDownloadExcel = (cols, data, fileName) => {
    data.unshift(cols)
    const wb = XLSX.utils.book_new()
    const ws = XLSX.utils.aoa_to_sheet(data)
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet')
    XLSX.writeFile(wb, fileName)
  }

  const formatDate = (date) => {
    let month = `${date.getMonth() + 1}` // Months are zero-indexed
    let day = `${date.getDate()}`
    const year = date.getFullYear()

    if (month.length < 2) month = `0${month}`
    if (day.length < 2) day = `0${day}`

    return [year, month, day].join('_')
  }
  const exportParcels = async () => {
    const { response } = await dispatch(getParcelsExport(exportPeriod))

    const cols = Object.keys(response[0]).filter(
      (key) => !ignoredParcelKeys.includes(key)
    )

    const data = response.map((r) => {
      const row = []
      Object.entries(r).forEach(([key, value]) => {
        if (ignoredParcelKeys.includes(key)) return

        let col = value
        if (key === 'deliveredTime') {
          const inBoxStatus = value.find((v) => v.name === 'in-box')
          if (!inBoxStatus) {
            col = 'Not delivered'
          } else {
            col = inBoxStatus.created
          }
        }
        row.push(col)
      })
      return row
    })
    const startDate = new Date(new Date() - exportPeriod * 24 * 60 * 60 * 1000)
    const endDate = new Date()
    createAndDownloadExcel(
      cols,
      data,
      `parcel-export-${formatDate(startDate)}-${formatDate(endDate)}.xlsx`
    )
  }

  const exportLocations = async () => {
    const { response } = await dispatch(getLocationsExport())

    const cols = Object.keys(response[0]).filter(
      (key) => !ignoredLocationsKeys.includes(key)
    )

    const data = response.map((r) => {
      const row = []
      Object.entries(r).forEach(([key, value]) => {
        if (ignoredLocationsKeys.includes(key)) return

        let col = value
        if (key === 'coordinates' && Array.isArray(value))
          col = value.join(', ')

        if (
          key === 'locationDescriptionShort' &&
          carrier.username === 'schenker'
        )
          col = `iBoxen, ${value}`

        row.push(col)
      })
      return row
    })

    createAndDownloadExcel(
      cols,
      data,
      `location-export-${window.systemDate()}.xlsx`
    )
  }

  return (
    <Modal
      ariaHideApp={false}
      isOpen
      onRequestClose={closeModal}
      style={window.modal.large}
    >
      <CloseModalButton closeModal={closeModal} />
      <br />

      <Container>
        <Row>
          <Col>
            <select
              value={exportPeriod}
              onChange={(e) => setExportPeriod(e.target.value)}
              style={{ padding: '7px 5px', width: '100%' }}
            >
              <option value={1}>{t('prev24Hours')}</option>
              <option value={7}>{t('prev7Days')}</option>
              <option value={30}>{t('prev30Days')}</option>
            </select>
          </Col>
          <Col>
            <div className="ibButton" onClick={exportParcels}>
              {t('exportParcels')}
            </div>
          </Col>
        </Row>
        <br />
        <hr />
        <br />
        <Row>
          <Col xs={6} />
          <Col xs={6}>
            <div className="ibButton" onClick={exportLocations}>
              {t('exportLocations')}
            </div>
          </Col>
        </Row>
      </Container>
    </Modal>
  )
}

export default ExportModal
