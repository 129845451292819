import React, { useState, useEffect, useMemo } from 'react'
import { Modal, Paper, Box, Button, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { getLocations } from '../../../redux/locations/slice.locations'
import { LocationImages } from './LocationImages'
import { LocationAllocations, boxStyles } from './LocationAllocations'
import { getParcels } from '../../../redux/parcels/slice.parcels'

const sortDescending = (array) => {
  return array.sort((a, b) => b - a)
}

const compareArrayBounds = (arr1, arr2) =>
  arr1[0] <= arr2[0] && arr1[1] <= arr2[1] && arr1[2] <= arr2[2]

const groupParcelsBySize = (parcels, location) => {
  const boxTypesSortedBySize = [
    ...location.boxTypes.filter((boxType) => boxType.active !== false),
  ].sort((a, b) => {
    const aDimensions = sortDescending([...a.dimensionsHBD])
    const bDimensions = sortDescending([...b.dimensionsHBD])

    if (compareArrayBounds(aDimensions, bDimensions)) return -1
    if (compareArrayBounds(bDimensions, aDimensions)) return 1
    return 0
  })

  return parcels.reduce((groups, parcel) => {
    let minBoxTypeIndex = boxTypesSortedBySize.findIndex(({ dimensionsHBD }) =>
      compareArrayBounds(
        sortDescending([...parcel.dimensions]),
        sortDescending([...dimensionsHBD])
      )
    )
    if (minBoxTypeIndex < 0) minBoxTypeIndex = boxTypesSortedBySize.length

    if (!groups[minBoxTypeIndex]) {
      // eslint-disable-next-line no-param-reassign
      groups[minBoxTypeIndex] = [
        boxTypesSortedBySize[minBoxTypeIndex]?.display?.friendlyName?.default ||
          'N/A',
        [parcel],
      ]
    } else {
      groups[minBoxTypeIndex][1].push(parcel)
    }

    return groups
  }, [])
}

export default function LocationModal({ locationId, onClose }) {
  const dispatch = useDispatch()
  const location = useSelector((state) => state.locations.cache[locationId])
  const {
    data: { parcels },
  } = useSelector((state) => state.parcels)
  const { t } = useTranslation('locations')
  const [showLocationImages, setShowLocationImages] = useState(false)
  const [isExpanded, setIsExpanded] = useState(false)

  useEffect(() => {
    dispatch(
      getLocations({
        ids: locationId.toString(),
        incAllocatedBoxes: true,
        incBoxTypes: true,
      })
    )
  }, [dispatch, locationId])

  useEffect(() => {
    dispatch(getParcels())
  }, [dispatch, locationId])

  const onwayParcelsWithoutBoxIdGroupedBySize = useMemo(() => {
    if (!location) return []
    return (
      groupParcelsBySize(
        parcels.filter(
          (parcel) =>
            parcel.isSoftAllocation &&
            ['on-way', 'pickup-on-way'].includes(parcel.currentStatus) &&
            parcel.location?._id?.toString() === location._id?.toString()
        ),
        location
      ) ?? []
    )
  }, [parcels, location])

  return (
    <Modal open onClose={onClose}>
      <Paper
        sx={{
          width: '90%',
          height: '90vh',
          mx: 'auto',
          my: '5vh',
          overflowY: 'auto',
          position: 'relative',
          zIndex: 0,
          p: 10,
          pt: 2,
        }}
      >
        <Box sx={{ float: 'right' }}>
          {location && !showLocationImages && (
            <Button
              variant="contained"
              onClick={() => setShowLocationImages(true)}
              sx={{ mr: 1 }}
            >
              {t('locationImages')}
            </Button>
          )}
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              if (showLocationImages) {
                setShowLocationImages(false)
                return
              }
              onClose()
            }}
          >
            {t('close', { ns: 'common' })}
          </Button>
        </Box>

        {location && (
          <>
            <Typography variant="h3">
              {t('locationAddress', { location })}
            </Typography>
            <Typography>
              {t('locationBoxCount', { count: location.boxes.length })}
            </Typography>
            <Typography>
              {location.desciptions?.default?.SV ||
                location.locationDescriptionShort}
            </Typography>
            <Typography>
              {location.desciptions?.default?.EN ||
                location.locationDescriptionShort_EN}
            </Typography>
            <div
              style={{
                width: '100%',
                display: 'flex',
                alignItems: 'flex-start',
                justifyContent: 'center',
                flexDirection: 'column',
              }}
            >
              {onwayParcelsWithoutBoxIdGroupedBySize.length > 0 && (
                <>
                  <Button
                    variant="contained"
                    onClick={() => setIsExpanded((bool) => !bool)}
                    style={{ marginBottom: '20px', marginTop: '10px' }}
                  >
                    {t(isExpanded ? 'hide' : 'show', {
                      count: onwayParcelsWithoutBoxIdGroupedBySize.flatMap(
                        (group) => group[1]
                      ).length,
                    })}
                  </Button>
                  {isExpanded && (
                    <Typography
                      variant="h4"
                      mb={1}
                      style={{ alignSelf: 'flex-start' }}
                    >
                      {t('parcelsOnwayTitle')}
                    </Typography>
                  )}
                  <table
                    border={isExpanded ? '1' : '0'}
                    cellPadding="10"
                    style={{
                      alignSelf: 'flex-start',
                      borderCollapse: 'separate',
                      borderColor: '#bbb',
                    }}
                  >
                    {isExpanded ? (
                      <tbody>
                        {onwayParcelsWithoutBoxIdGroupedBySize.map(
                          (sizeGroup) => (
                            <tr
                              style={{
                                width: '100%',
                              }}
                            >
                              <td
                                style={{
                                  minWidth: '120px',
                                  marginRight: '4px',
                                  padding: 2,
                                }}
                              >
                                {sizeGroup[0]}
                              </td>
                              <td
                                style={{
                                  display: 'flex',
                                  flexWrap: 'wrap',
                                  padding: 2,
                                }}
                              >
                                {sizeGroup[1].map((parcel) => (
                                  <div
                                    style={{
                                      ...boxStyles({ parcel }),
                                      padding: '4px',
                                      borderWidth: '1px',
                                      borderStyle: 'solid',
                                      textAlign: 'center',
                                      fontSize: '12px',
                                      borderRadius: '4px',
                                      margin: '2px',
                                    }}
                                  >
                                    <div>{parcel.parcelId}</div>
                                  </div>
                                ))}
                              </td>
                            </tr>
                          )
                        )}
                      </tbody>
                    ) : null}
                  </table>
                </>
              )}
            </div>
            <Box
              mt={2}
              sx={{
                width: '100%',
                display: 'flex',
                alignItems: 'flex-start',
                justifyContent: 'center',
                flexDirection: 'column',
              }}
            >
              {showLocationImages ? (
                <LocationImages location={location} />
              ) : (
                <>
                  <Typography variant="h4" mb={2} textAlign="center">
                    {t('parcelsInboxTitle')}
                  </Typography>
                  <LocationAllocations location={location} />
                </>
              )}
            </Box>
          </>
        )}
      </Paper>
    </Modal>
  )
}
