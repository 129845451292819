import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  LayerGroup,
  Circle,
} from 'react-leaflet'

export default function LocationsMap({ locations, locationSelected }) {
  const queriedAddress = locations[0]
  const { t } = useTranslation('locations')

  const [map, setMap] = useState(null)

  useEffect(() => {
    const bounds = locations
      .map(({ coordinates }) => {
        if (Array.isArray(coordinates)) return coordinates
        return [coordinates.lat, coordinates.lng]
      })
      .filter((f) => Array.isArray(f) && f.length)
    if (map) {
      setTimeout(() => {
        map.fitBounds(bounds.slice(0, 1))
        map.setZoom(16)
      }, 250)
    }
  }, [locations, map])

  const createLocationMarker = (location, i) => {
    return (
      <Marker key={i} position={location.coordinates}>
        <Popup>
          <h5>
            {location.address.street}, {location.address.zip}{' '}
            {location.address.city}
          </h5>
          <span>
            {t('availableBoxes')}:{location.numberOfAvailableBoxes}
          </span>
          <br />
          <span>
            {t('distanceFromAddress')}: {location.distanceFromQueriedAddress}
          </span>
          <br />
          <div className="ibButton" onClick={() => locationSelected(location)}>
            {t('bookBox')}
          </div>
        </Popup>
      </Marker>
    )
  }

  return (
    <div>
      <MapContainer whenCreated={setMap} zoom={17} scrollWheelZoom tap={false}>
        <TileLayer
          id="mapbox.streets"
          url="http://{s}.tile.osm.org/{z}/{x}/{y}.png"
          maxZoom={18}
          crossOrigin
          attribution='&copy; <a href="https://www.mapbox.com">OpenStreetMap</a> contributors'
        />

        <LayerGroup>
          <Circle
            center={queriedAddress.coordinates}
            pathOptions={{ fillColor: 'green', color: 'green' }}
            radius={45}
          />
        </LayerGroup>

        {locations.slice(1).map(createLocationMarker)}
      </MapContainer>
    </div>
  )
}
