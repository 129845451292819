import login from './login.json'
import carriers from './carriers.json'
import locations from './locations.json'
import common from './common.json'
import parcels from './parcels.json'
import responseMessages from './responseMessages.json'
import users from './users.json'

export default {
  login,
  responseMessages,
  carriers,
  locations,
  common,
  parcels,
  users,
}
