import React, { useState, useMemo, useEffect, useCallback } from 'react'
import { TextField, Popover, Tabs, Tab, Grid, Box, Button } from '@mui/material'
import { DateTimePicker } from '@mui/x-date-pickers'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'

const relativeDataset = {
  minutes: [5, 10, 15, 20, 30, 45],
  hours: [1, 2, 3, 6, 8, 12],
  days: [1, 2, 3, 4, 5, 6],
  weeks: [1, 2, 3, 4, 5, 6],
}

export default function DateFilter({
  value: initialValue,
  onChange,
  InputProps,
  InputLabelProps,
  ...props
}) {
  const formattedValue = useMemo(() => {
    return {
      type: 'absolute',
      unit: undefined,
      value: undefined,
      ...initialValue,
      fromDate: initialValue?.fromDate ? dayjs(initialValue.fromDate) : null,
      toDate: initialValue?.toDate ? dayjs(initialValue.toDate) : null,
    }
  }, [initialValue])
  const [value, setValue] = useState(formattedValue)
  const [datePopoverAnchor, setDatePopoverAnchor] = useState()
  const { t } = useTranslation('common')

  const handleChange = useCallback(
    (updates) => {
      const newValue = { ...value, ...updates }
      if (typeof onChange === 'function') {
        onChange({
          ...newValue,
          fromDate: newValue.fromDate?.valueOf(),
          toDate: newValue.toDate?.valueOf(),
        })
      }
      setValue(newValue)
    },
    [onChange, value]
  )

  useEffect(() => {
    setValue(formattedValue)
  }, [formattedValue])

  const displayedValue = useMemo(() => {
    const {
      type,
      fromDate,
      toDate,
      value: relativeValue,
      unit: relativeUnit,
    } = value
    if (type === 'absolute' && (fromDate || toDate)) {
      return [fromDate, toDate].map((d) => d?.format('L LT')).join(' - ')
    }
    if (type === 'relative' && relativeValue && relativeUnit) {
      return [relativeValue, relativeUnit].join(' ')
    }
    return undefined
  }, [value])
  return (
    <>
      <TextField
        onClick={(event) => setDatePopoverAnchor(event.target)}
        onKeyDown={(event) => setDatePopoverAnchor(event.target)}
        value={displayedValue || ''}
        InputProps={{ readOnly: true, ...InputProps }}
        InputLabelProps={{
          shrink: !!displayedValue,
          ...InputLabelProps,
        }}
        {...props}
      />
      <Popover
        open={Boolean(datePopoverAnchor)}
        anchorEl={datePopoverAnchor}
        onClose={() => setDatePopoverAnchor(null)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        <Tabs value={value.type} onChange={(_, type) => handleChange({ type })}>
          <Tab label="Absolute" value="absolute" />
          <Tab label="Relative" value="relative" />
        </Tabs>
        <Box p={2}>
          {value.type === 'absolute' && (
            <Grid container spacing={2}>
              <Grid item>
                <DateTimePicker
                  label={t('fromDate')}
                  value={value.fromDate}
                  onChange={(fromDate) => handleChange({ fromDate })}
                />
              </Grid>
              <Grid item>
                <DateTimePicker
                  label={t('toDate')}
                  value={value.toDate}
                  onChange={(toDate) => handleChange({ toDate })}
                />
              </Grid>
            </Grid>
          )}
          {value.type === 'relative' && (
            <table cellPadding={8}>
              <tbody>
                {Object.entries(relativeDataset).map(([unit, data]) => (
                  <tr key={t(unit)}>
                    <td>{t(unit)}</td>
                    {data.map((v) => (
                      <td key={`${unit}-${v}`}>
                        <Button
                          variant={
                            value.unit === unit && value.value === v
                              ? 'contained'
                              : 'outlined'
                          }
                          onClick={() => handleChange({ value: v, unit })}
                        >
                          {v}
                        </Button>
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </Box>
      </Popover>
    </>
  )
}
