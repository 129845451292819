import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import {
  Box,
  Button,
  FormControlLabel,
  Radio,
  TextField,
  Grid,
  Typography,
  RadioGroup,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
} from '@mui/material'
import { putUser } from '../../../redux/users/slice.users'
import validate from '../../../utils/validate'
import { PasswordInput } from '../../../common/PasswordInput'
import { formatDateTime } from './ListUsers'

export function Edituser({ mode = 'new', user, roles, afterSubmit }) {
  const { t } = useTranslation('users')
  const dispatch = useDispatch()

  const [username, setUsername] = useState(user?.username || '')
  const [role, setRole] = useState(user?.role ?? null)
  const [password, setPassword] = useState('')
  const auditTrail = user?.auditTrail || []
  const getAuditTrailActionText = (action) => {
    switch (action.type) {
      case 'password':
        return t('changedPassword')
      case 'role':
        return t('changedRole', { role: action.role })
      case 'create':
        return t('createdUser')
      default:
        return 'Unknown'
    }
  }
  const handleSubmit = (e) => {
    e.preventDefault()
    let payload
    if (mode === 'new') {
      payload = { username, role, password }
    } else {
      payload = { _id: user._id, role }
      if (password) {
        payload.password = password
      }
    }
    dispatch(putUser(payload)).then(({ error }) => {
      if (!error && typeof afterSubmit === 'function') {
        afterSubmit()
      }
    })
  }
  const validatePassword = (value) => {
    if (!value && mode !== 'new') return true
    return validate.password(value)
  }

  const canSubmit = () => {
    if (mode === 'new') {
      return role && username.length >= 6 && validatePassword(password)
    }
    return role !== user.role || (password && validatePassword(password))
  }

  return (
    <>
      <Typography variant="h2" sx={{ marginBottom: '10px' }}>
        {mode === 'new' ? t('createUser') : t('editUser')}
      </Typography>
      <Grid container alignItems="center" spacing={4}>
        <Grid item xs={2}>
          <Typography variant="h5">
            {t('username', { ns: 'common' })}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <TextField
            fullWidth
            error={mode === 'new' && username?.length < 6}
            value={username}
            disabled={mode === 'edit'}
            onChange={(e) => setUsername(e.target.value)}
          />
        </Grid>
        <Grid item xs={6}>
          <Typography>{t('userNameMustContainAtLeast6Characters')}</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="h5">
            {t('password', { ns: 'common' })}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <PasswordInput
            fullWidth
            error={!validatePassword(password)}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </Grid>
        <Grid item xs={6}>
          <Typography>
            {t(
              'passwordMustContainAtLeast8CharactersConsistingOfAtLeastOneUppercaseLetterOneLowercaseLetterOneNumberAndOneSpecialCharacter'
            )}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h5" sx={{ marginBottom: '5px' }}>
            {t('role', { ns: 'common' })}
          </Typography>
          <RadioGroup
            name="user-role"
            value={role}
            onChange={(e) => setRole(e.target.value)}
          >
            {roles.map((r) => (
              <FormControlLabel
                key={r._id}
                label={r.niceName}
                value={r._id}
                control={<Radio />}
                sx={{ marginRight: 'auto', marginBottom: 0 }}
              />
            ))}
          </RadioGroup>
        </Grid>
      </Grid>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-end',
        }}
      >
        <Button
          variant="contained"
          disabled={!canSubmit()}
          onClick={handleSubmit}
          sx={{ alignSelf: 'flex-end' }}
        >
          {t('save', { ns: 'common' })}
        </Button>
      </Box>
      <Box>
        <Typography variant="h5" sx={{ marginBottom: '5px' }}>
          {t('auditTrail')}
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ width: '200px' }}>{t('time')}</TableCell>
                <TableCell sx={{ width: '200px' }}>{t('user')}</TableCell>
                <TableCell>{t('action')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {auditTrail.map((a) => (
                <TableRow key={a.date}>
                  <TableCell sx={{ width: '200px' }}>
                    {formatDateTime(a.date)}
                  </TableCell>
                  <TableCell sx={{ width: '200px' }}>{a.user}</TableCell>
                  <TableCell>{getAuditTrailActionText(a)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Typography>
      </Box>
    </>
  )
}
