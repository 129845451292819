import React from 'react'
import Form from '@rjsf/core'
import { Container, Row, Col } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import { useTranslation } from 'react-i18next'

export default function ParcelDetails({ selectedLocation, submit }) {
  const { t } = useTranslation('parcels')
  const [formData, setFormData] = React.useState({})
  const schema = {
    title: '',
    type: 'object',
    properties: {
      receiver: {
        title: '',
        type: 'object',
        properties: {
          phoneNumber: {
            type: 'string',
          },
          email: { type: 'string' },
          firstName: { type: 'string' },
          lastName: { type: 'string' },
        },
        required: 'phoneNumber firstName lastName'.split(' '),
      },
      delivery: {
        title: '',
        type: 'object',
        properties: {
          parcelId: {
            type: 'string',
          },
          dimensionH: { type: 'number' },
          dimensionB: { type: 'number' },
          dimensionD: { type: 'number' },
        },
      },

      senderName: { type: 'string' },
      requiresLowBox: { title: t('lowBox'), type: 'boolean' },
    },
    required: 'senderName'.split(' '),
  }

  const uiSchema = {
    receiver: {
      'ui:hidden': true,
      phoneNumber: {
        'ui:title': t('receiverPhone'),
        classNames: 'smallFormWidth',
      },
      email: {
        'ui:title': t('receiverEmail'),
        classNames: 'smallFormWidth',
      },
      firstName: {
        'ui:title': t('receiverFirstName'),
        classNames: 'smallFormWidth',
      },
      lastName: {
        'ui:title': t('receiverLastName'),
        classNames: 'smallFormWidth',
      },
    },
    delivery: {
      parcelId: {
        'ui:title': t('parcelId'),
        classNames: 'smallFormWidth',
      },
      dimensionH: {
        'ui:title': t('parcelHeight'),
        classNames: 'smallFormWidth',
      },
      dimensionB: {
        'ui:title': t('parcelWidth'),
        classNames: 'smallFormWidth',
      },
      dimensionD: {
        'ui:title': t('parcelDepth'),
        classNames: 'smallFormWidth',
      },
    },
    senderName: {
      'ui:title': t('senderName'),
      classNames: 'smallFormWidth',
    },
    requiresLowBox: {
      'ui:title': t('lowBox'),
      classNames: 'smallFormWidth',
    },
  }

  const mockedFormData = React.useMemo(
    () => ({
      delivery: {
        dimensionH: 0,
        dimensionB: 0,
        dimensionD: 0,
      },
      ...formData,
    }),
    [formData]
  )

  const handleSubmit = React.useCallback(
    (v) => {
      setFormData(v.formData)
      submit(v.formData)
    },
    [submit]
  )

  return (
    <Container style={{ height: '98vh' }}>
      <Row>
        <Col {...window.bCol()}>
          <h5>
            {t('bookingsForLocker')}: {selectedLocation.address.street},{' '}
            {selectedLocation.address.zip} {selectedLocation.address.city}
          </h5>
        </Col>
      </Row>
      <br />
      <Row>
        <Col {...window.bCol()}>
          <Form
            onSubmit={handleSubmit}
            schema={schema}
            uiSchema={uiSchema}
            formData={mockedFormData}
          >
            <button type="submit" className="ibButton ibSuccess">
              {t('sendBooking')}
            </button>
          </Form>
        </Col>
      </Row>
    </Container>
  )
}
