import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { getLocationsByAddress } from '../../redux/actions'
import SearchLocationInput from '../SearchLocationInput'

function GetLocations({ setLocations }) {
  const [input, setInput] = useState({ street: '', city: '', zip: '' })
  const [error, setError] = useState(null)
  const { t } = useTranslation('locations')

  const setAddress = (v) => {
    setError(null)
    setInput(v)
  }

  const dispatch = useDispatch()

  const fetchLocations = async (e) => {
    e.preventDefault()
    if (!input.street) {
      setError(t('chooseAddress'))
    } else if (!input.zip || !input.city) {
      setError(t('chooseStreet'))
    } else {
      const { response } = await dispatch(getLocationsByAddress(input))
      setLocations(response)
    }
  }

  return (
    <div>
      <form className="d-flex" onSubmit={fetchLocations}>
        <SearchLocationInput onChange={setAddress} />
        <button className="ibButton ml-3" type="submit">
          {t('getLocations')}
        </button>
      </form>
      {error && <p className="text-danger text-left mb-0 mt-2">{error}</p>}
    </div>
  )
}

export default GetLocations
