import React from 'react'

export default function TextInputField({
  onKeyPress,
  fieldName,
  placeHolder,
  fieldWidth = '100%',
  name,
  type = 'text',
  value,
  onChange,
  id = '',
}) {
  return (
    <div style={{ display: 'flex', fieldWidth }}>
      {fieldName ? <div style={{ width: '25%' }}>{fieldName}</div> : null}
      <div style={{ width: fieldName ? '75%' : '100%', textAlign: 'center' }}>
        <input
          onKeyPress={onKeyPress}
          placeholder={placeHolder}
          type={type}
          id={id}
          className="textInput"
          name={name}
          value={value || ''}
          onChange={onChange}
        />
      </div>
    </div>
  )
}
