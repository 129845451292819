import React, { useState } from 'react'

const operands = ['<', '=', '>']

function getNextOperand(operand) {
  let n = 0
  const i = operands.indexOf(operand)
  if (i !== -1 && i < operands.length - 1) {
    n = i + 1
  }
  return operands[n]
}

export default function AdvancedNumberFilter({
  column: { filterValue, setFilter, min, max },
}) {
  const [operand, setOperand] = useState('=')
  return (
    <span style={{ display: 'flex' }}>
      <button
        type="button"
        className="ibButton"
        style={{
          width: 35,
          borderBottomRightRadius: 0,
          borderTopRightRadius: 0,
        }}
        onClick={() => {
          const next = getNextOperand(operand)
          setOperand(next)

          if (filterValue) {
            setFilter(`${next}${filterValue.substr(1)}`)
          }
        }}
      >
        {operand}
      </button>
      <input
        type="number"
        className="textInput"
        value={filterValue ? filterValue.substr(1) : ''}
        onChange={(e) => {
          setFilter(e.target.value ? `${operand}${e.target.value}` : undefined) // Set undefined to remove the filter entirely
        }}
        min={min}
        max={max}
        style={{
          borderBottomLeftRadius: 0,
          borderTopLeftRadius: 0,
        }}
      />
    </span>
  )
}
