import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import Modal from 'react-modal'
import CloseModalButton from '../CloseModalButton'
import 'jsoneditor-react/es/editor.min.css'
import GetLocations from './GetLocations'
import LocationsMap from './LocationsMap'
import ParcelDetails from './ParcelDetails'
import { createParcel } from '../../redux/actions'
import { getParcels, updateFilters } from '../../redux/parcels/slice.parcels'

export default function CreateParcelModal({ closeModal }) {
  const dispatch = useDispatch()

  const [locations, setLocations] = useState(null)
  const [selectedLocation, setSelectedLocation] = useState(null)

  useEffect(() => {
    window.location.href = '#selectedLocationElement'
  }, [selectedLocation])

  const registerParcel = React.useCallback(
    (data) => {
      const parcel = { ...data }
      parcel.preferedLocations = [selectedLocation.locationId]

      parcel.delivery.dimensions = []

      'H B D'.split(' ').forEach((char) => {
        parcel.delivery.dimensions.push(parcel.delivery[`dimension${char}`])
        delete parcel.delivery[`dimension${char}`]
      })

      dispatch(createParcel(parcel)).then((t) => {
        if (t.error) return
        dispatch(updateFilters({ flow: 'delivery' }))
        dispatch(getParcels()).then(closeModal)
      })
    },
    [dispatch, selectedLocation, closeModal]
  )

  const memoizedSubmit = React.useCallback(
    (formData) => {
      registerParcel(formData)
    },
    [registerParcel]
  )

  return (
    <Modal ariaHideApp={false} isOpen style={window.modal.large}>
      <CloseModalButton closeModal={closeModal} />
      <div
        className="mt-4"
        style={{ backgroundColor: '#f1f1f1f1', padding: 10 }}
      >
        <GetLocations setLocations={setLocations} />
      </div>

      {locations ? (
        <LocationsMap
          locations={locations}
          locationSelected={setSelectedLocation}
        />
      ) : null}
      <br />

      <div id="selectedLocationElement">
        {selectedLocation ? (
          <div style={{ height: '75vh' }}>
            <ParcelDetails
              selectedLocation={selectedLocation}
              submit={memoizedSubmit}
            />
          </div>
        ) : null}
        <br />
      </div>

      <br />

      <br />
    </Modal>
  )
}
