import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { TextField, InputAdornment, IconButton } from '@mui/material'
import { Visibility, VisibilityOff } from '@mui/icons-material'

export function PasswordInput({
  type,
  showPassword,
  onToggleVisibility,
  ...props
}) {
  const { t } = useTranslation('common')
  const [localShowPassword, setLocalShowPassword] = useState(false)
  useEffect(() => {
    setLocalShowPassword(showPassword)
  }, [showPassword])
  return (
    <TextField
      type={localShowPassword ? type || 'text' : 'password'}
      InputProps={{
        endAdornment: (
          <InputAdornment position="start">
            <IconButton
              aria-label={t('togglePasswordVisibility')}
              onClick={() => {
                setLocalShowPassword(!localShowPassword)
                if (typeof onToggleVisibility === 'function')
                  onToggleVisibility(!localShowPassword)
              }}
              edge="end"
            >
              {localShowPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        ),
      }}
      {...props}
    />
  )
}
