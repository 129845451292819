import { createAsyncThunk } from '@reduxjs/toolkit'
import i18n from '../../lang/i18n'
import swaler from '../../utils/swaler'
import request from '../../utils/fetcher'

let ac
export default createAsyncThunk('getCarriers', async (payload, thunkApi) => {
  if (ac) {
    try {
      ac.abort()
    } catch {
      // Silent
    }
  }
  try {
    ac = new AbortController()
    const state = thunkApi.getState()
    return await request(
      'GET',
      `/carriers/`,
      {
        ...state.carriers.filters,
        ...state.carriers.sorting,
        ...state.carriers.pagination,
        ...payload,
      },
      { signal: ac.signal }
    )
  } catch (e) {
    if (e.name !== 'AbortError') {
      let { message } = e
      if (i18n.exists(`responseMessages:${message.replaceAll(' ', '-')}`)) {
        message = i18n.t(`responseMessages:${message.replaceAll(' ', '-')}`)
      }
      swaler.error(message)
    }
    throw e
  } finally {
    ac = undefined
  }
})
