import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import {
  MapContainer,
  TileLayer,
  Popup,
  Marker,
  Polyline,
  Tooltip,
  useMapEvents,
} from 'react-leaflet'
import { Trans, useTranslation } from 'react-i18next'

import { activeIcon, inactiveIcon, createPolyLines, agentIcon } from './utils'

const DEBOUNCE_POLYLINES_TIMEOUT = 2000
const DEFAULT_MAP_POSITION = [59.341268, 18.064822]

function MapEvents({ events }) {
  useMapEvents(events)
  return null
}

export default function Map({
  locations,
  agentLocations,
  updateLocation,
  position = DEFAULT_MAP_POSITION,
  setPosition,
}) {
  const [maxDistance, setMaxDistance] = useState(200)
  const { t } = useTranslation('locations')
  const [showAgentLocations, setShowAgentLocations] = useState(false)
  const [showAgentPolylines, setShowAgentPolylines] = useState(false)
  const [zoomLevel, setZoomLevel] = useState(18)
  const [polylines, setPolylines] = useState(
    createPolyLines(
      locations,
      maxDistance,
      showAgentPolylines
        ? agentLocations.map((loc) => ({
            ...loc,
            coordinates: loc.coordinates.coordinates,
          }))
        : []
    )
  )

  useEffect(() => {
    // Prevent immediate update of polylines so as to now overload system.
    const timeout = setTimeout(() => {
      setPolylines(
        createPolyLines(
          locations,
          maxDistance,
          showAgentPolylines
            ? agentLocations.map((loc) => ({
                ...loc,
                coordinates: loc.coordinates.coordinates,
              }))
            : []
        )
      )
    }, DEBOUNCE_POLYLINES_TIMEOUT)
    return () => clearTimeout(timeout)
  }, [locations, maxDistance, agentLocations, showAgentPolylines])

  return (
    <div
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
      }}
    >
      <MapContainer
        tap={false}
        id="locations-map"
        center={position}
        zoom={10}
        zoomControl={false}
        style={{ height: '100%' }}
      >
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {locations.map(
          ({
            _id,
            address,
            coordinates,
            desc,
            controllers,
            boxCount,
            carrierActive,
          }) => (
            <Marker
              key={_id}
              position={coordinates}
              icon={carrierActive ? activeIcon : inactiveIcon}
              style={{ width: '100%' }}
            >
              <Popup>
                <div style={{ marginBottom: '1em' }}>
                  {address.street}
                  <br />
                  {address.zip} {address.city}
                </div>
                {desc}
                <br />
                {t('numberOfLockers')}: {controllers}
                <br />
                {t('numberOfBoxes')}: {boxCount}
                <div style={{ width: '100%', marginTop: 20 }}>
                  <Button
                    variant={carrierActive ? 'danger' : 'success'}
                    onClick={() =>
                      updateLocation(_id, {
                        carrierActive: !carrierActive,
                      })
                    }
                  >
                    {carrierActive ? t('deactivate') : t('activate')}
                  </Button>
                </div>
              </Popup>
            </Marker>
          )
        )}
        {showAgentLocations &&
          zoomLevel > 10 &&
          agentLocations.map(({ _id, address, coordinates, name }) => (
            <Marker
              key={_id}
              position={coordinates.coordinates}
              icon={agentIcon}
              style={{ width: '100%' }}
            >
              <Popup>
                <p style={{ margin: '0 0 5px 0', fontWeight: 'bold' }}>
                  {t('carrierPickupPoint')}
                </p>
                <div style={{ marginBottom: '1em' }}>
                  {address.street}
                  <br />
                  {address.zip} {address.city}
                </div>
                {name}
              </Popup>
            </Marker>
          ))}
        {zoomLevel > 10 &&
          polylines.map(({ fromLocation, toLocation, distance, color }) => (
            <Polyline
              key={`${fromLocation._id}-${toLocation._id}`}
              positions={[fromLocation.coordinates, toLocation.coordinates]}
              pathOptions={{ color }}
              weight={5}
            >
              <Tooltip direction="center" opacity={1}>
                {Math.floor(distance)}m
              </Tooltip>
            </Polyline>
          ))}
        <MapEvents
          events={{
            move: (e) => {
              setPosition(e.target.getCenter())
            },
            zoomend: (e) => {
              /* eslint-disable */
              setZoomLevel(e.target._zoom)
              /* eslint-enable */
            },
          }}
        />
        <div
          className="leaflet-top leaflet-left leaflet-right"
          style={{ padding: 10, width: '100%' }}
        >
          <div
            className="leaflet-control leaflet-bar"
            style={{
              background: '#fff',
              width: '100%',
              textAlign: 'left',
              margin: 0,
              padding: 15,
              paddingTop: 60,
            }}
          >
            <h6 style={{ margin: 0 }}>
              {t('minDistance')}{' '}
              <input
                type="number"
                value={maxDistance}
                onChange={(e) => setMaxDistance(e.target.value)}
              />{' '}
              <Trans i18nKey="locations:meterResult" count={polylines.length} />
            </h6>
            {agentLocations.length && (
              <div
                style={{
                  display: 'flex',
                  padding: '10px',
                  width: '500px',
                  justifyContent: 'space-between',
                }}
              >
                <Button
                  variant={showAgentLocations ? 'danger' : 'success'}
                  onClick={() => setShowAgentLocations(!showAgentLocations)}
                >
                  {showAgentLocations
                    ? t('hideCarrierPickupPoints')
                    : t('showCarrierPickupPoints')}
                </Button>
                {showAgentLocations && (
                  <Button
                    variant={showAgentPolylines ? 'danger' : 'success'}
                    onClick={() => setShowAgentPolylines(!showAgentPolylines)}
                  >
                    {showAgentPolylines
                      ? t('excludeCarrierPickupPoints')
                      : t('includeCarrierPickupPoints')}
                  </Button>
                )}
              </div>
            )}
          </div>
        </div>
      </MapContainer>
    </div>
  )
}
