import { Icon } from 'leaflet'

export const activeIcon = new Icon({
  iconUrl:
    'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-green.png',
  shadowUrl:
    'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
})

export const inactiveIcon = new Icon({
  iconUrl:
    'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-red.png',
  shadowUrl:
    'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
})

export const agentIcon = new Icon({
  iconUrl:
    'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-orange.png',
  shadowUrl:
    'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
})

function calculateDistance(loc1, loc2) {
  if (loc1[0] === loc2[0] && loc1[1] === loc2[1]) {
    return 0
  }

  const radloc1lat = (Math.PI * loc1[0]) / 180
  const radloc2lat = (Math.PI * loc2[0]) / 180
  const theta = loc1[1] - loc2[1]
  const radtheta = (Math.PI * theta) / 180
  let dist =
    Math.sin(radloc1lat) * Math.sin(radloc2lat) +
    Math.cos(radloc1lat) * Math.cos(radloc2lat) * Math.cos(radtheta)
  if (dist > 1) {
    dist = 1
  }
  dist = Math.acos(dist)
  dist = (dist * 180) / Math.PI
  dist = dist * 60 * 1.1515
  dist *= 1.609344
  return dist * 1000
}

const randomColor = () => {
  const n = (Math.random() * 0xfffff * 1000000).toString(16)
  return `#${n.slice(0, 6)}`
}

export const createPolyLines = (
  locations,
  maxDistance,
  agentLocations = []
) => {
  const activeLocations = [
    ...locations.filter(({ carrierActive }) => carrierActive),
    ...agentLocations,
  ]

  const polylines = []
  activeLocations.forEach((fromLocation) => {
    activeLocations.forEach((toLocation) => {
      if (fromLocation === toLocation) return
      const distance = calculateDistance(
        fromLocation.coordinates,
        toLocation.coordinates
      )
      if (distance > maxDistance) return
      polylines.push({
        fromLocation,
        toLocation,
        distance,
        color: randomColor(),
      })
    })
  })
  return polylines
}
