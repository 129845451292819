import React, { useEffect, useMemo, useState } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import Modal from 'react-modal'
import { useDispatch, useSelector } from 'react-redux'
import PasswordStrengthBar from 'react-password-strength-bar'

import { useTranslation } from 'react-i18next'
import CloseModalButton from './CloseModalButton'
import { getDrivers, createDriver, deleteDriver } from '../redux/actions'
import TextInputField from './TextInputField'
import EditDriverModal from './EditDriverModal'
import swaler from '../utils/swaler'
import Table from './Table'

export default function DriverSettingsModal({ closeModal }) {
  const dispatch = useDispatch()
  const { t } = useTranslation('common')
  const drivers = useSelector((state) => state.root.drivers)

  const [createDriverInput, setCreateDriverInput] = useState(null)
  const [editDriver, setEditDriver] = useState(null)

  const [passwordScore, setPasswordScore] = useState(null)

  useEffect(() => {
    dispatch(getDrivers())
  }, [dispatch])

  const submitDriver = async () => {
    if (passwordScore < 2) swaler.warn(t('passwordTooWeak'))
    else
      dispatch(createDriver(createDriverInput)).then((data) => {
        if (!data.error) {
          setCreateDriverInput(null)
        }
      })
  }

  const updateInput = ({ target }) => {
    setCreateDriverInput({ ...createDriverInput, [target.name]: target.value })
  }

  const renderCreateDriverInputFields = () => {
    return (
      <div style={{ padding: '1%' }}>
        <div style={{ width: '100%' }}>
          <TextInputField
            fieldName={t('username')}
            name="username"
            value={createDriverInput.username}
            onChange={updateInput}
          />
        </div>
        <br />
        <div style={{ width: '100%' }}>
          <TextInputField
            fieldName={t('password')}
            name="password"
            value={createDriverInput.password}
            onChange={updateInput}
          />
          <div style={{ paddingLeft: '50%', marginTop: 30 }}>
            <PasswordStrengthBar
              style={{ width: '55%' }}
              onChangeScore={setPasswordScore}
              password={createDriverInput.password}
            />
          </div>
        </div>
        <br />
        <div className="ibButton ibButtonSuccess" onClick={submitDriver}>
          {t('save')}
        </div>
      </div>
    )
  }

  const HeaderCell = useMemo(() => {
    const removeDriver = (driver) => {
      swaler
        .confirm(t('confirmAccountRemoval', { username: driver.username }))
        .then((data) => {
          if (data.isConfirmed) {
            dispatch(deleteDriver(driver._id))
          }
        })
    }
    /* eslint-disable-next-line */
    return function ({ row: { original } }) {
      return (
        <div style={{ display: 'flex' }}>
          <button
            type="button"
            className="ibButton"
            style={{ width: '50%', marginRight: 10 }}
            onClick={() => setEditDriver(original)}
          >
            {t('update')}
          </button>
          <button
            type="button"
            className="ibButton"
            style={{ backgroundColor: '#bb0000', color: 'white' }}
            onClick={() => removeDriver(original)}
          >
            {t('removeDeactivate')}
          </button>
        </div>
      )
    }
  }, [dispatch, t])

  const columns = useMemo(
    () => [
      {
        Header: t('username'),
        id: 'username',
        accessor: (d) => d.username,
        Filter: 'text',
        filter: 'includes',
      },
      {
        Header: t('lastActive'),
        id: 'lastActive',
        accessor: (d) => d.lastActive,
        Filter: 'text',
        filter: 'includes',
      },
      {
        Header: t('deviceInfo'),
        id: 'deviceInfo',
        accessor: () => t('commingSoon'),
        Filter: 'text',
        filter: 'includes',
      },
      {
        Header: '',
        id: 'driverActions',
        Cell: HeaderCell,
      },
    ],
    [HeaderCell, t]
  )

  return (
    <div>
      {editDriver ? (
        <EditDriverModal
          driver={editDriver}
          closeModal={() => setEditDriver(null)}
        />
      ) : null}
      <Modal
        ariaHideApp={false}
        isOpen
        onRequestClose={closeModal}
        style={window.modal.large}
      >
        <CloseModalButton closeModal={closeModal} />
        <br />

        <Container>
          <Row>
            <Col {...window.bCol()}>
              <div
                className="ibButton"
                onClick={() =>
                  createDriverInput
                    ? setCreateDriverInput(null)
                    : setCreateDriverInput({})
                }
              >
                {t('addDriver')}
              </div>
            </Col>
          </Row>
          {createDriverInput ? (
            <Row>
              <Col {...window.bCol()}>{renderCreateDriverInputFields()}</Col>
            </Row>
          ) : null}
          <br />
          <hr />

          <h4>{t('drivers')}:</h4>
          <br />

          <Table columns={columns} data={drivers || []} />
        </Container>
      </Modal>
    </div>
  )
}
