import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Box,
  ButtonGroup,
  Button,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableSortLabel,
} from '@mui/material'
import ContentCopy from '@mui/icons-material/ContentCopy'
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch'
import { visuallyHidden } from '@mui/utils'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import ParcelsFilters from '../common/ParcelsFilters'
import PaginationToolbar from '../shared/PaginationToolbar'
import {
  getParcels,
  setPagination,
  setSorting,
} from '../redux/parcels/slice.parcels'
import { Line } from '../shared/Line'
import { useMe } from '../hooks/useMe'

export default function ParcelsView() {
  const dispatch = useDispatch()
  const { t } = useTranslation('parcels')
  const { data, sorting, pagination, cache } = useSelector(
    (state) => state.parcels
  )
  const { user, carrier } = useMe()

  useEffect(() => {
    dispatch(getParcels())
  }, [dispatch, carrier])

  const columns = useMemo(
    () => [
      {
        id: 'flow',
        type: String,
        label: t('type'),
        sortable: true,
        render: (row) => t(`flows.${row.flow ? row.flow : 'delivery'}`),
        width: 85,
      },
      {
        id: 'parcelId',
        type: String,
        label: t('parcelId'),
        sortable: true,
        render: (row) => (
          <ButtonGroup
            size="small"
            variant="outlined"
            fullWidth
            sx={{ width: '100%' }}
          >
            <Button
              onClick={() => {
                dispatch({
                  type: 'setShowModal',
                  payload: { modal: 'parcel', selectedParcel: row._id },
                })
              }}
              fullWidth
            >
              <span
                style={{
                  textWrap: 'nowrap',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  textTransform: 'none',
                  maxWidth: '100%',
                }}
              >
                {row.parcelId}
              </span>
            </Button>
            <Button
              onClick={() => navigator.clipboard.writeText(row.parcelId)}
              sx={{ px: 0 }}
              style={{ minWidth: 0, width: 35 }}
            >
              <ContentCopy sx={{ fontSize: 15 }} />
            </Button>
          </ButtonGroup>
        ),
        width: 270,
      },
      {
        id: 'created',
        type: Date,
        label: t('created'),
        sortable: true,
        render: (row) => dayjs(row.created || row.createdAt).format('L LT'),
        width: 145,
      },
      {
        id: 'currentStatus',
        type: String,
        label: t('status'),
        sortable: true,
        render: (row) => t(`statuses.${row.currentStatus}`),
        width: 200,
      },
      {
        id: 'currentStatusCreated',
        type: Date,
        label: t('statusDate'),
        sortable: true,
        render: (row) => dayjs(row.currentStatusCreated).format('L LT'),
        width: 145,
      },
      {
        id: 'locationAddress',
        type: String,
        label: t('address'),
        sortable: false,
        render: (row) =>
          row.location?.address?.street && (
            <Button
              size="small"
              variant="outlined"
              onClick={() => {
                dispatch({
                  type: 'setShowModal',
                  payload: {
                    modal: 'location',
                    selectedLocation: row.location._id,
                  },
                })
              }}
              fullWidth
            >
              <span
                style={{
                  textWrap: 'nowrap',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  textTransform: 'none',
                  maxWidth: '100%',
                }}
              >
                {row.location?.address?.street}
              </span>
            </Button>
          ),
        width: 250,
      },
      {
        id: 'locationZip',
        type: String,
        label: t('zip'),
        sortable: false,
        render: (row) => row.location?.address?.zip,
        width: 75,
      },
      {
        id: 'locationCity',
        type: String,
        label: t('city'),
        sortable: false,
        render: (row) => row.location?.address?.city,
        width: 140,
      },
      {
        id: 'compartment',
        type: String,
        label: t('compartment'),
        sortable: false,
        width: 120,
      },
      {
        id: 'trace',
        type: String,
        label: t('trace'),
        sortable: false,
        render: (row) => (
          <Box>
            <Button
              variant="contained"
              size="small"
              sx={{ px: 1 }}
              style={{ minWidth: 0 }}
            >
              <ContentPasteSearchIcon
                fontSize="small"
                onClick={() =>
                  dispatch({
                    type: 'setShowModal',
                    payload: { modal: 'traceLogs', selectedParcel: row._id },
                  })
                }
              />
            </Button>
          </Box>
        ),
        width: 70,
        hidden: !user.isAllowedTo('viewTraceLogs'),
      },
    ],
    [dispatch, t, user]
  )

  if (!user || !carrier) return <p>{t('loading')}</p>

  const toolbar = (
    <PaginationToolbar
      spacing={0.5}
      count={data.metadata?.totalCount || 0}
      rowsPerPageOptions={[10, 20, 40, 100]}
      rowsPerPage={pagination.perPage}
      page={pagination.page}
      onPageChange={(_, page) => {
        dispatch(setPagination({ page }))
        dispatch(getParcels())
      }}
      onRowsPerPageChange={(e) => {
        dispatch(setPagination({ perPage: parseInt(e.target.value, 10) }))
        dispatch(getParcels())
      }}
    />
  )

  return (
    <>
      <Paper sx={{ p: 1, mb: 2.5 }}>
        <ParcelsFilters
          spacing={1}
          onSubmit={() => {
            dispatch(setPagination({ page: 0 }))
            dispatch(getParcels())
          }}
        />
      </Paper>
      <Paper sx={{ background: '#e8e0d8', pb: 0.5, mb: 2 }}>
        {toolbar}
        <Line mt={0.5} mb={0} />
        <TableContainer sx={{ pb: 0.5 }}>
          <Table size="small" sx={{ tableLayout: 'fixed' }}>
            <TableHead>
              <TableRow sx={{ bgcolor: 'rgba(241, 241, 241, 0.745)' }}>
                {columns.map(
                  (column) =>
                    !column.hidden && (
                      <TableCell
                        component="th"
                        key={column.id}
                        align={column.type === Number ? 'right' : 'left'}
                        width={column.width}
                      >
                        {column.sortable ? (
                          <TableSortLabel
                            active={sorting.orderBy === column.id}
                            direction={
                              sorting.orderBy === column.id
                                ? sorting.order
                                : undefined
                            }
                            onClick={() => {
                              let order = 'desc'
                              let orderBy = column.id
                              if (
                                sorting.orderBy === column.id &&
                                sorting.order === 'asc'
                              ) {
                                order = undefined
                                orderBy = undefined
                              } else if (sorting.orderBy === column.id) {
                                order = 'asc'
                              }
                              dispatch(setSorting({ orderBy, order }))
                              dispatch(getParcels())
                            }}
                          >
                            {column.label}
                            {sorting.orderBy === column.id ? (
                              <Box component="span" sx={visuallyHidden}>
                                {sorting.order === 'desc'
                                  ? 'sorted descending'
                                  : 'sorted ascending'}
                              </Box>
                            ) : null}
                          </TableSortLabel>
                        ) : (
                          column.label
                        )}
                      </TableCell>
                    )
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.parcels
                .map(({ _id }) => cache[_id])
                .map((parcel, i) => (
                  <TableRow
                    tabIndex={-1}
                    key={parcel._id}
                    sx={{ bgcolor: i % 2 && 'rgba(241, 241, 241, 0.745)' }}
                  >
                    {columns.map(
                      (column) =>
                        !column.hidden && (
                          <TableCell
                            key={`${parcel._id}-${column.id}`}
                            align={column.type === Number ? 'right' : 'left'}
                            sx={column.sx}
                          >
                            {typeof column.render === 'function'
                              ? column.render(parcel)
                              : parcel[
                                  typeof column.selector === 'function'
                                    ? column.selector(parcel)
                                    : column.selector || column.id
                                ]}
                          </TableCell>
                        )
                    )}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        {toolbar}
      </Paper>
    </>
  )
}
