import React, { useState, useEffect } from 'react'
import { Modal, Paper, Box, Button } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { getRoles, getUsers } from '../../../redux/users/slice.users'
import { Edituser } from './EditUser'
import { ListUsers } from './ListUsers'

export default function UsersModal({ onClose }) {
  const dispatch = useDispatch()
  const { filters } = useSelector((state) => state.users)
  const { t } = useTranslation('common')
  const [selectedUser, setSelectedUser] = useState(null)
  const [roles, setRoles] = useState([])
  const [editMode, setEditMode] = useState(null)

  useEffect(() => {
    dispatch(getUsers())
    dispatch(getRoles()).then(({ type, payload }) => {
      if (type === 'getRoles/fulfilled') {
        setRoles(payload)
      }
    })
  }, [dispatch])

  // eslint-disable-next-line no-unused-expressions, no-unused-vars
  const d = [selectedUser, filters]

  return (
    <Modal open onClose={onClose}>
      <Paper
        sx={{
          width: '90%',
          height: '90vh',
          mx: 'auto',
          my: '5vh',
          overflowY: 'auto',
          position: 'relative',
          zIndex: 0,
          p: 10,
        }}
      >
        <Box sx={{ float: 'right' }}>
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              if (editMode) {
                setEditMode(null)
                setSelectedUser(null)
              } else {
                onClose()
              }
            }}
          >
            {t('close')}
          </Button>
        </Box>
        {editMode ? (
          <Edituser
            mode={editMode}
            user={selectedUser}
            roles={roles}
            afterSubmit={() => {
              dispatch(getUsers())
              setEditMode(null)
              setSelectedUser(null)
            }}
          />
        ) : (
          <ListUsers
            roles={roles}
            onNew={() => {
              setEditMode('new')
              setSelectedUser(null)
            }}
            onEdit={(user) => {
              setEditMode('edit')
              setSelectedUser(user)
            }}
          />
        )}
      </Paper>
    </Modal>
  )
}
