import React, { useState } from 'react'
import Modal from 'react-modal'
import { useDispatch } from 'react-redux'
import PasswordStrengthBar from 'react-password-strength-bar'

import { useTranslation } from 'react-i18next'
import CloseModalButton from './CloseModalButton'
import { updateDriver } from '../redux/actions'
import TextInputField from './TextInputField'
import swaler from '../utils/swaler'

export default function EditDriverModal({ closeModal, driver }) {
  const dispatch = useDispatch()
  const { t } = useTranslation('common')

  const [updatedDriver, setUpdatedDriver] = useState({})
  const [passwordScore, setPasswordScore] = useState(null)

  const updateInput = ({ target }) => {
    setUpdatedDriver({ ...updatedDriver, [target.name]: target.value })
  }

  const submitDriver = () => {
    if (passwordScore < 2) swaler.warn(t('passwordTooWeak'))
    else
      dispatch(
        updateDriver({ _id: driver._id, updateFields: updatedDriver })
      ).then((data) => {
        if (!data.error) {
          closeModal()
        }
      })
  }

  return (
    <div>
      <Modal
        ariaHideApp={false}
        isOpen
        onRequestClose={closeModal}
        style={window.generateModalSize(0.2)}
      >
        <CloseModalButton text="X" closeModal={closeModal} />
        <br />

        <div style={{ padding: '5%' }}>
          <div style={{ width: '100%' }}>
            <TextInputField
              fieldName={t('username')}
              name="username"
              value={updatedDriver.username || driver.username}
              onChange={updateInput}
            />
          </div>
          <br />
          <div style={{ width: '100%' }}>
            <TextInputField
              fieldName={t('password')}
              name="password"
              value={updatedDriver.password}
              onChange={updateInput}
            />
            <div style={{ paddingLeft: '50%', marginTop: 30 }}>
              <PasswordStrengthBar
                style={{ width: '55%' }}
                onChangeScore={setPasswordScore}
                password={updatedDriver.password}
              />
            </div>
          </div>
          <br />
          <div className="ibButton ibButtonSuccess" onClick={submitDriver}>
            {t('save')}
          </div>
        </div>
      </Modal>
    </div>
  )
}
