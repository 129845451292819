import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { renderShortAddressString } from '../utils'
import { theme } from '../theme'
import { useMe } from '../hooks/useMe'

export default function ParcelCard({ parcel }) {
  const dispatch = useDispatch()
  const { t } = useTranslation('parcels')
  const parcelFlow = useSelector((state) => state.parcels.filters.flow)
  const { user } = useMe()

  return (
    <div>
      <div
        style={{
          backgroundColor: theme(parcelFlow).secondary,
          color: theme(parcelFlow).color,
          padding: 3,
          marginBottom: 5,
          borderRadius: 5,
        }}
      >
        <div>
          <span style={{ fontSize: 14 }}>
            {window.smallDate(
              parcel.statuses.length &&
                parcel.statuses[parcel.statuses.length - 1].created
            )}
          </span>
        </div>

        {parcel.location && (
          <div
            className="ibLink"
            style={{ color: theme(parcelFlow).color }}
            onClick={() =>
              dispatch({
                type: 'setShowModal',
                payload: {
                  modal: 'location',
                  selectedLocation: parcel.location._id,
                  selectedParcel: parcel._id,
                },
              })
            }
          >
            {renderShortAddressString(parcel.location.address)}
          </div>
        )}

        <span
          className="ibLink"
          style={{ color: theme(parcelFlow).dark }}
          onClick={() =>
            dispatch({
              type: 'setShowModal',
              payload: { modal: 'parcel', selectedParcel: parcel._id },
            })
          }
        >
          {parcel.parcelId}
        </span>

        <br />

        <div style={{ display: 'flex' }}>
          {user.isAllowedTo('viewTraceLogs') ? (
            <div
              className="ibLink"
              onClick={() =>
                dispatch({
                  type: 'setShowModal',
                  payload: { modal: 'traceLogs', selectedParcel: parcel._id },
                })
              }
              style={{
                flex: 1,
                color: theme(parcelFlow).dark,
              }}
            >
              {t('viewTraceLogs')}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  )
}
