import { createAsyncThunk } from '@reduxjs/toolkit'
import i18n from '../../lang/i18n'
import swaler from '../../utils/swaler'
import request from '../../utils/fetcher'

const parseFilters = (filters = {}) => {
  const parsed = {}

  Object.entries(filters || {}).forEach(([key, value]) => {
    if (
      value === undefined ||
      value === null ||
      (value instanceof Array && value.length === 0)
    )
      return

    if (['created', 'currentStatusCreated'].includes(key)) {
      const data =
        value.type === 'absolute'
          ? [value.fromDate || '', value.toDate || '']
          : [value.value, value.unit]
      // eslint-disable-next-line no-param-reassign
      value = [value.type, ...data].join('|')
    }

    parsed[key] = value
  })

  return parsed
}

let ac
export default createAsyncThunk('getParcels', async (payload, thunkApi) => {
  if (ac) {
    try {
      ac.abort()
    } catch {
      // Silent
    }
  }
  try {
    ac = new AbortController()
    const state = thunkApi.getState()
    return await request(
      'GET',
      `/carrier/parcels`,
      {
        type: 'new',
        ...parseFilters({
          ...state.parcels.filters,
          ...(!state.root.legacyView && {
            daysBack: undefined,
            ...state.parcels.sorting,
            ...state.parcels.pagination,
          }),
          ...payload,
        }),
      },
      {
        signal: ac.signal,
        headers: { 'x-iboxen-carrier': state.root.carrierHeader },
      }
    )
  } catch (e) {
    if (e.name !== 'AbortError') {
      let { message } = e
      if (i18n.exists(`responseMessages:${message.replaceAll(' ', '-')}`)) {
        message = i18n.t(`responseMessages:${message.replaceAll(' ', '-')}`)
      }
      swaler.error(message)
    }
    throw e
  } finally {
    ac = undefined
  }
})
