/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'
import getLocations from './async.getLocations'
import getLocationImages from './async.getLocationImages'

export const initialState = {
  loading: false,
  error: undefined,
  cache: {
    images: {},
  },
  data: {
    locations: [],
    metadata: {},
    images: [],
  },
  filters: {},
  sorting: { orderBy: undefined, order: undefined },
  pagination: { page: 0, perPage: 20 },
}

export const locationsSlice = createSlice({
  name: 'locations',
  initialState,
  reducers: {
    resetFilters(state, action) {
      state.filters = { ...initialState.filters, ...action.payload }
    },
    updateFilters(state, action) {
      state.filters = {
        ...initialState.filters,
        ...state.filters,
        ...action.payload,
      }
    },
    setPagination(state, action) {
      state.pagination = {
        ...state.pagination,
        ...action.payload,
      }
    },
    setSorting(state, action) {
      state.sorting = {
        ...state.sorting,
        ...action.payload,
      }
    },
    cacheLocations(state, action) {
      action.payload.location.forEach((location) => {
        state.cache[location._id] = location
      })
    },
    cacheLocation(state, action) {
      state.cache[action.payload._id] = action.payload
    },
  },
  extraReducers(builder) {
    builder
      // getLocations
      .addCase(getLocations.pending, (state) => {
        state.loading = true
      })
      .addCase(getLocations.rejected, (state, action) => {
        if (action.error.name !== 'AbortError') {
          state.error = action.payload ?? action.error
        }
      })
      .addCase(getLocations.fulfilled, (state, action) => {
        state.error = undefined
        const locations = []
        action.payload.locations.forEach((location) => {
          location.$cached = new Date().getTime()
          state.cache[location._id] = location
          locations.push(state.cache[location._id])
        })
        state.data = {
          ...state.data,
          ...action.payload,
          locations,
        }
      })

      // getLocationImages
      .addCase(getLocationImages.fulfilled, (state, action) => {
        state.data.images[action.meta.arg.id] = action.payload
      })

      // Matchers
      // getLocations
      .addMatcher(getLocations.settled, (state) => {
        state.loading = false
      })
  },
})

// Action creators are generated for each case reducer function
export const {
  resetFilters,
  updateFilters,
  setPagination,
  setSorting,
  cacheLocations,
  cacheLocation,
} = locationsSlice.actions

export { getLocations, getLocationImages }

export default locationsSlice.reducer
