import React from 'react'
import { MenuItem, Select, FormControl, InputLabel } from '@mui/material'
import { useTranslation } from 'react-i18next'

export default function LanguageSelector() {
  const { i18n, t } = useTranslation('common')

  // Handle language change
  const handleLanguageChange = (event) => {
    i18n.changeLanguage(event.target.value)
  }

  return (
    <FormControl sx={{ maxHeight: '20px', padding: 0 }} size="small">
      <InputLabel
        sx={{ fontSize: '0.5rem', marginBottom: '4px' }}
        id="language-select-label"
      >
        {t('language')}
      </InputLabel>
      <Select
        labelId="language-select-label"
        id="language-select"
        value={i18n.language || 'en'}
        label={t('language')}
        sx={{ fontSize: '0.5rem', marginTop: '-6px' }}
        onChange={handleLanguageChange}
      >
        <MenuItem sx={{ fontSize: '0.5rem' }} value="en">
          EN
        </MenuItem>
        <MenuItem sx={{ fontSize: '0.5rem' }} value="sv">
          SV
        </MenuItem>
        <MenuItem sx={{ fontSize: '0.5rem' }} value="de">
          DE
        </MenuItem>
      </Select>
    </FormControl>
  )
}
