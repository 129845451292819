import React, { useState } from 'react'
import { Modal, Paper, Box, Button, Stack, Typography } from '@mui/material'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { changeCarrierPassword } from '../redux/actions'
import { PasswordInput } from './PasswordInput'
import validate from '../utils/validate'

const defaultState = {
  oldPassword: '',
  newPassword1: '',
  newPassword2: '',
}

export default function ChangePasswordModal({
  onClose,
  requiresOldPassword = true,
}) {
  const dispatch = useDispatch()
  const { t } = useTranslation('common')

  const [input, setInput] = useState({ ...defaultState })

  const validateAll = () => {
    return !(
      (requiresOldPassword && !input.oldPassword) ||
      !input.newPassword1 ||
      input.newPassword1 !== input.newPassword2 ||
      !validate.password(input.newPassword1)
    )
  }

  return (
    <Modal open onClose={onClose}>
      <Paper
        sx={{
          width: '400px',
          mx: 'auto',
          my: '15vh',
          overflowY: 'auto',
          position: 'relative',
          zIndex: 0,
          p: 4,
        }}
      >
        <form
          onSubmit={async (e) => {
            e.preventDefault()

            if (!validateAll()) return

            dispatch(changeCarrierPassword(input)).then(({ error }) => {
              if (!error) {
                onClose()
                setInput({ ...defaultState })
              }
            })
          }}
          style={{ width: '100%' }}
        >
          <Stack spacing={2} sx={{ width: '100%' }}>
            <Box>
              <Button
                variant="contained"
                color="error"
                onClick={onClose}
                sx={{ float: 'right' }}
              >
                {t('close')}
              </Button>
            </Box>
            {requiresOldPassword && (
              <PasswordInput
                value={input.oldPassword}
                onChange={(e) =>
                  setInput({ ...input, oldPassword: e.target.value })
                }
                label={t('oldPassword')}
              />
            )}
            <Typography>
              {t(
                'passwordMustContainAtLeast8CharactersConsistingOfAtLeastOneUppercaseLetterOneLowercaseLetterOneNumberAndOneSpecialCharacter',
                { ns: 'users' }
              )}
            </Typography>
            <PasswordInput
              value={input.newPassword1}
              onChange={(e) =>
                setInput({ ...input, newPassword1: e.target.value })
              }
              error={
                !!(input.newPassword1 && !validate.password(input.newPassword1))
              }
              label={t('newPassword')}
            />
            <PasswordInput
              value={input.newPassword2}
              onChange={(e) =>
                setInput({ ...input, newPassword2: e.target.value })
              }
              error={
                !!(
                  input.newPassword1 &&
                  input.newPassword2 &&
                  input.newPassword1 !== input.newPassword2
                )
              }
              label={t('repeatPassword')}
            />
            <Button variant="contained" type="submit" disabled={!validateAll()}>
              {t('save')}
            </Button>
          </Stack>
        </form>
      </Paper>
    </Modal>
  )
}
