const isSuperUser = (user) => {
  if (!user?.role) return false
  if (user.role.name === 'iboxen-admin') return true
  return false
}

const userIsAllowedTo = (user, action) => {
  if (!user?.role) return false
  if (isSuperUser(user)) return true
  return user.role.allowedTo.includes(action)
}

const carrierIsAllowedTo = (carrier, action) => {
  if (!carrier) return false
  return carrier.allowedTo.includes(action)
}

export { isSuperUser, userIsAllowedTo, carrierIsAllowedTo }
