import React, { useEffect, useState, useMemo } from 'react'
import { Container, Row, Col, Table } from 'react-bootstrap'
import Modal from 'react-modal'
import { useDispatch } from 'react-redux'

import { useTranslation } from 'react-i18next'
import CloseModalButton from './CloseModalButton'
import { getSettings, saveSettings } from '../redux/actions'

const exampleValues = {
  senderName: 'Butik Företag Warehouse c/o butiken',
  locationStreet: 'Hammarby Fabriksväg 101',
  locationZip: '12033',
  locationCity: 'Stockholm',
  parcelId: '373327921031176000',
  carrierName: 'Transport AB',
  reminderTimeLeft: '24',
  appLink: 'www.iboxen.se/app',
}

const exampleMessages = {
  'on-way':
    'Hej, ditt paket med id {parcelId} är på väg till iBoxen på {locationStreet}. Du får ett nytt SMS när paketet levererats till iBoxen. För att hämta paketet behöver du senaste versionen av iBoxen app och mobilt BankID. Länk {appLink}',
  'in-box':
    'Ditt paket med id {parcelId} är levererat till iBoxen på {locationStreet}. Ladda ned iBoxen app på samma mobil som du har BankID redan nu så går det snabbare att hämta ditt paket. Länk {appLink}',
  'parcel-pickup-reminder':
    'Hej, du behöver hämta ditt paket med id {parcelId} inom {reminderTimeLeft} timmar, annars skickas det i retur till avsändaren.',
  'marked-for-return':
    'Hej, ditt paket med id {parcelId} hämtades inte i tid och kommer nu att skickas tillbaka till avsändaren.',
}

const parseTextMessageTemplate = (template) => {
  let value = template
  if (!value) return ''
  Object.keys(exampleValues).forEach((key) => {
    value = value.split(`{${key}}`).join(`${exampleValues[key]}`)
  })

  return value
}

const countExtraMessage = (sms) => {
  return Math.ceil(sms.length / 160)
}

export default function MessageSettingsModal({ closeModal }) {
  const { statusOptions } = window
  const defaultTriggerInput = {
    triggerCode: statusOptions.messageStatuses[0],
    textMessageTemplate: exampleMessages[statusOptions.messageStatuses[0]],
    show: true,
  }
  const { t: transCommon } = useTranslation('common')
  const { t: transParcels } = useTranslation('parcels')
  const dispatch = useDispatch()
  const [messageSettings, setMessageSettings] = useState(null)
  const [statusOption, setStatusOption] = useState('on-way')
  const [newTriggerInput, setNewTriggerInput] = useState(defaultTriggerInput)
  const parsedTextMessage = useMemo(
    () => parseTextMessageTemplate(newTriggerInput.textMessageTemplate),
    [newTriggerInput.textMessageTemplate]
  )

  useEffect(() => {
    dispatch(getSettings()).then((data) => {
      setMessageSettings(data.response.message)
    })
  }, [dispatch])

  const removeTrigger = (index) => {
    setMessageSettings({
      ...messageSettings,
      integrationEvents: messageSettings.integrationEvents.filter(
        (_, ii) => index !== ii
      ),
    })
  }

  const eventRow = ({ type, textMessageTemplate, triggerCode }, i) => {
    return (
      <div
        key={i}
        style={{
          border: '1px solid lightgrey',
          padding: 5,
          marginBottom: '45px',
          textAlign: 'left',
        }}
      >
        {type !== 'email' && (
          <button
            type="button"
            className="ibXRemoveButton"
            onClick={() => removeTrigger(i)}
          >
            X
          </button>
        )}
        <div
          style={{ padding: 3, marginTop: 3, textAlign: 'left', width: '65%' }}
        >
          <Table style={{ marginTop: '10px' }} size="sm" bordered>
            <thead>
              <tr>
                <th>{transCommon('type')}</th>
                <th>Händelse</th>
                {type !== 'email' && (
                  <>
                    <th>{transCommon('avgLength')} </th>
                    <th>{transCommon('numberOfSms')}</th>
                  </>
                )}
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{type || 'sms'}</td>
                <td>{transCommon(triggerCode)}</td>
                {type !== 'email' && (
                  <>
                    <td>
                      {parseTextMessageTemplate(textMessageTemplate).length}{' '}
                      {transCommon('chars')}
                    </td>
                    <td>
                      {countExtraMessage(
                        parseTextMessageTemplate(textMessageTemplate)
                      )}
                    </td>
                  </>
                )}
              </tr>
            </tbody>
          </Table>
          {type !== 'email' && (
            <p style={{ marginBottom: '5px' }}>
              {transCommon('message')}: {textMessageTemplate}
            </p>
          )}
        </div>
        {type !== 'email' && (
          <>
            <hr />
            <div style={{ padding: 3, textAlign: 'left', width: '65%' }}>
              <p style={{ marginBottom: '5px' }}> {transCommon('example')}</p>
              <p>{parseTextMessageTemplate(textMessageTemplate)}</p>
            </div>
          </>
        )}
      </div>
    )
  }

  const addTrigger = () => {
    setMessageSettings({
      ...messageSettings,
      integrationEvents:
        messageSettings.integrationEvents.concat(newTriggerInput),
    })
    setStatusOption('on-way')
    setNewTriggerInput(defaultTriggerInput)
  }

  const renderNewMessageInput = () => {
    return (
      <div style={{ textAlign: 'left' }}>
        <select
          value={statusOption}
          style={{ width: '100%' }}
          onChange={(e) => {
            setStatusOption(e.target.value)
            setNewTriggerInput({
              ...newTriggerInput,
              triggerCode: e.target.value,
              textMessageTemplate: exampleMessages[e.target.value],
            })
          }}
        >
          {statusOptions.messageStatuses.map((o) => (
            <option key={o} value={o}>
              {transParcels(`statuses.${o}`)}
            </option>
          ))}
        </select>
        <br />
        <br />
        <div>
          <p style={{ fontWeight: 'bold', marginBottom: '5px' }}>Template </p>
          <textarea
            style={{ width: '100%' }}
            className="ibTextareaInput"
            placeholder="Meddelande"
            value={newTriggerInput.textMessageTemplate}
            onChange={(e) =>
              setNewTriggerInput({
                ...newTriggerInput,
                textMessageTemplate: e.target.value,
              })
            }
          />
        </div>
      </div>
    )
  }

  const save = () => {
    if (!messageSettings) return
    dispatch(saveSettings('message', messageSettings))
  }

  useEffect(save, [dispatch, messageSettings])

  if (!messageSettings) return null

  return (
    <Modal
      ariaHideApp={false}
      isOpen
      onRequestClose={closeModal}
      style={window.modal.large}
    >
      <br />

      <Container fluid>
        <CloseModalButton closeModal={closeModal} />
        <Row>
          <h4>{transCommon('createNewMessage')}</h4>
          {newTriggerInput.show ? (
            <div>
              <Row style={{ marginTop: '30px', textAlign: 'left' }}>
                <Col md={4}>{renderNewMessageInput()}</Col>
                <Col md={4}>
                  <p
                    style={{
                      fontWeight: 'bold',
                      marginTop: '45px',
                      marginBottom: '5px',
                    }}
                  >
                    {transCommon('preview')}{' '}
                  </p>
                  <div
                    style={{
                      textAlign: 'left',
                      border: '1px solid #e5e5e5',
                      padding: '10px',
                      minHeight: '200px',
                    }}
                  >
                    {parsedTextMessage}
                  </div>
                  <div style={{ marginTop: '10px' }}>
                    <p style={{ marginBottom: '5px' }}>
                      {transCommon('calcNumberOfSms')}{' '}
                    </p>
                    <p style={{ marginBottom: '5px' }}>
                      {transCommon('numberOfChars')}:
                      <b>{parsedTextMessage.length}</b>
                    </p>
                    <p>
                      {transCommon('numberOfSmsCharge')}:{' '}
                      <b>{Math.ceil(parsedTextMessage.length / 160)}</b>
                    </p>
                  </div>
                </Col>
                <Col md={4}>
                  <Table size="sm" bordered>
                    <thead>
                      <tr>
                        <th>{transCommon('parameter')}</th>
                        <th>{transCommon('example')}</th>
                        <th>{transCommon('meanLength')}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Object.keys(exampleValues).map((key) => (
                        <tr>
                          <td>{key}</td>
                          <td>{exampleValues[key]}</td>
                          <td>{exampleValues[key].length}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  <p>{transCommon('smsCharsExplained')}</p>
                </Col>
              </Row>
              <Row>
                <Col md={2}>
                  <div className="ibButton iEnquiry" onClick={addTrigger}>
                    {transCommon('save')}
                  </div>
                </Col>
                <Col {...window.bCol(2)}>
                  <div
                    className="ibButton"
                    onClick={() =>
                      newTriggerInput.show
                        ? setNewTriggerInput(defaultTriggerInput)
                        : setNewTriggerInput({
                            triggerCode: statusOptions.all[0],
                            show: true,
                          })
                    }
                  >
                    {transCommon('abort')}
                  </div>
                </Col>
                <Col {...window.bCol(12)}>
                  <hr style={{ marginBottom: '35px', marginTop: '45px' }} />
                  {messageSettings.integrationEvents.length ? (
                    <h4 style={{ textAlign: 'left', marginBottom: '20px' }}>
                      {transCommon('savedMessages')}
                    </h4>
                  ) : (
                    <h4 style={{ textAlign: 'left', marginBottom: '20px' }}>
                      {transCommon('noMessages')}!
                    </h4>
                  )}
                </Col>
                <Col md={12}>
                  {messageSettings.integrationEvents.map(eventRow)}
                </Col>
              </Row>
            </div>
          ) : null}
        </Row>
      </Container>
    </Modal>
  )
}
