import React, { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { CircularProgress, Grid, Typography } from '@mui/material'
import { getLocationImages } from '../../../redux/locations/slice.locations'

export function LocationImages({ location }) {
  const { t } = useTranslation('locations')
  const dispatch = useDispatch()
  const locationImages = useSelector(
    (state) => state.locations.data.images[location._id.toString()]
  )
  const images = useMemo(
    () =>
      Object.values(locationImages || [])
        .reduce((a, v) => [...a, ...v], [])
        .filter((i) => new Date() < new Date(i.expiresAt)),
    [locationImages]
  )

  useEffect(() => {
    if (!locationImages) {
      dispatch(getLocationImages({ id: location._id.toString() }))
    }
  }, [dispatch, locationImages, location])

  if (!locationImages) {
    return <CircularProgress />
  }

  if (!images.length) {
    return <Typography>{t('noLocationImages')}</Typography>
  }

  return (
    <Grid container spacing={2}>
      {images.map((image) => (
        <Grid item md={4}>
          <img alt="" src={image.url} style={{ width: '100%' }} />
        </Grid>
      ))}
    </Grid>
  )
}
