import React from 'react'
import { Pagination } from 'react-bootstrap'

export default function TablePagination({ table }) {
  const {
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = table
  if (pageOptions.length <= 1) {
    return null
  }
  return (
    <Pagination>
      <select
        value={pageSize}
        onChange={(e) => setPageSize(Number(e.target.value))}
        style={{ marginRight: 15 }}
      >
        {[10, 20, 30, 40, 50, 100].map((size) => (
          <option key={size} value={size}>
            Show {size}
          </option>
        ))}
      </select>
      <Pagination.First
        onClick={() => gotoPage(0)}
        disabled={!canPreviousPage}
      />
      <Pagination.Prev
        onClick={() => previousPage()}
        disabled={!canPreviousPage}
      />
      <span style={{ padding: '0 10px' }}>
        <input
          type="number"
          value={pageIndex + 1}
          onChange={(e) => gotoPage(e.target.value)}
          style={{ width: '3em' }}
        />
        {` / `}
        {pageOptions.length}
      </span>
      <Pagination.Next onClick={() => nextPage()} disabled={!canNextPage} />
      <Pagination.Last
        onClick={() => gotoPage(pageCount - 1)}
        disabled={!canNextPage}
      />
    </Pagination>
  )
}
