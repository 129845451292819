import i18n from '../lang/i18n'
import { getBoxes } from '../redux/actions'

const renderAddressString = (address) => {
  if (!address) return 'Tom address'

  return `${address.street}, ${address.zip} ${address.city}`
}

const renderShortAddressString = (address) => {
  if (!address) return 'Empty address'

  return `${address.street}`
}

const statusTransitionServiceTranslation = (serviceChain = '') => {
  if (typeof serviceChain !== 'string' || !serviceChain) return 'N/A'

  const parsedService = serviceChain
    .split(' -> ')[0]
    .replaceAll('_', '-')
    .toLowerCase()

  switch (parsedService) {
    case 'service-integration':
    case 'service-delivery-api':
      return 'API'
    case 'parcel-manager':
      return 'Parcel Manager'
    case 'consumer':
    case 'service-consumer':
      return 'APP Consumer'
    case 'appsupport':
    case 'service-appsupport':
      return 'APP Driver'
    case 'iadmin':
      return 'Admin'
    case 'retool/iboxen':
    case 'retool/dynava':
      return 'iBoxen Backoffice'
    case 'retool/matka':
      return 'Matka Backoffice'
    default:
  }

  return parsedService
}

const getDriverFromLog = (log) => {
  if (typeof log !== 'string') return 'N/A'
  return log.split(/driver: ([^,]+)/)?.[1]?.trim() || 'N/A'
}

const formatParcelLogs = async (parcel, dispatch, t) => {
  const logs = []
  const tableLogs = (parcel.logs || []).filter((log) =>
    log.value?.startsWith('driver:')
  )

  // Filter out reallocations that were not made by the driver.
  const tableReallocations = (parcel.reallocations || [])
    .map((realloc) => {
      const driverLog = tableLogs.find(
        (log) =>
          log.type === 'payloads-create' &&
          new Date(log.created) - new Date(realloc.created) <= 10000 // payloads-create will always be created right after a reallocation from the driver
      )
      if (driverLog) {
        return {
          ...realloc,
          driver: getDriverFromLog(driverLog.value),
        }
      }
      return realloc
    })
    .filter((realloc) => Boolean(realloc.driver))

  const acceptedReasonsSearch = ['wrong location']

  tableLogs.forEach((v) => {
    if (i18n.exists(`parcels:driverLogsMap.${v.type}`)) {
      const reason = acceptedReasonsSearch.some((x) =>
        v.reason?.toLowerCase()?.includes(x)
      )
        ? ` | ${v.reason}`
        : ''

      const log = {
        log: `${t(`driverLogsMap.${v.type}`)}${
          v.inputMethod ? ` (${v.inputMethod}) ` : ' '
        }${`${v.isDoorClosed === false ? `- ${t('open')}` : ''}${
          v.isDoorClosed ? `- ${t('allClosed')}` : ''
        }`}${reason}`,
        friendlyDate: window.niceDate2(v.created),
        driver: getDriverFromLog(v.value),
      }

      logs.push(log)
    }
  })

  // eslint-disable-next-line no-plusplus
  if (tableReallocations.length) {
    const boxIds = tableReallocations.flatMap((v) => [v.fromBoxId, v.toBoxId])
    const res = await dispatch(getBoxes(boxIds))
    const { boxes } = res.response || {}

    const boxesGrouped = tableReallocations.map((v) => {
      const fromBox = boxes.find((box) => box._id === v.fromBoxId)
      const toBox = boxes.find((box) => box._id === v.toBoxId)
      return { fromBox, toBox, created: v.created, driver: v.driver }
    })

    boxesGrouped.forEach(({ fromBox, toBox, created, driver }) => {
      const log = {
        friendlyDate: window.niceDate2(created),
        driver,
      }
      if (boxes) {
        log.log = `Parcel reallocated from ${fromBox.port + 1 + fromBox.mark}(${
          fromBox.abbreviation
        }) to ${toBox.port + 1 + toBox.mark}(${toBox.abbreviation})`
      } else {
        log.log = 'Parcel reallocated'
      }
      logs.push(log)
    })
  }

  const logStatuses = logs.sort(
    (a, b) => new Date(a.friendlyDate) - new Date(b.friendlyDate)
  )

  return logStatuses
}

export {
  formatParcelLogs,
  renderAddressString,
  renderShortAddressString,
  statusTransitionServiceTranslation,
}
