/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react'
import { Grid } from '@mui/material'
import { Trans } from '../../../lang/i18n'

export const boxStyles = (box = {}) => {
  const styles = {
    borderColor: '#bbb',
  }
  if (box.parcel) {
    styles.backgroundColor = '#ccc'
  }
  return styles
}

export function ParcelInfo({ parcel, queuedParcel }) {
  return parcel ? (
    <div>
      <hr style={{ margin: '5px 0', borderColor: '#000' }} />
      {parcel && parcel.parcelId && (
        <p style={{ margin: 0 }}>
          <Trans
            i18nKey="lockerAllocations.parcelId"
            ns="locations"
            values={{ parcel }}
          />
        </p>
      )}
      <p style={{ margin: 0 }}>
        <Trans
          i18nKey="lockerAllocations.status"
          ns="locations"
          values={{ parcel }}
        />
      </p>
      {queuedParcel && (
        <p style={{ margin: 0 }}>
          <Trans
            i18nKey="lockerAllocations.queuedParcelId"
            ns="locations"
            values={{ parcel: queuedParcel }}
          />
        </p>
      )}
    </div>
  ) : null
}

function LockerBox({ boxType, box }) {
  return (
    <div>
      <p style={{ margin: 0 }}>
        <Trans
          i18nKey="lockerAllocations.box"
          ns="locations"
          values={{
            door: box.port + 1,
            boxType: boxType.display.friendlyName.default,
          }}
        />
      </p>
      {box && (box.parcel || box.queuedParcel) ? (
        <ParcelInfo parcel={box.parcel} queuedParcel={box.queuedParcel} />
      ) : null}
    </div>
  )
}

function Locker({ boxTypes, controller, boxes }) {
  const countLeft = ['v2', 'v3'].includes(controller.stationVersion) ? 9 : 8
  const mid = boxes.slice(countLeft, countLeft * 2)
  const right = boxes.length > 18 ? boxes.slice(countLeft * 2) : null
  const rows = boxes.slice(0, countLeft).map((box, i) => (
    <tr key={box._id}>
      <td width={right ? '33%' : '50%'} style={boxStyles(box)}>
        <LockerBox
          boxType={boxTypes.find((t) => t._id === box.boxType)}
          port={box.port}
          box={box}
        />
      </td>
      <td style={boxStyles(mid[i])}>
        {mid[i] ? (
          <LockerBox
            boxType={boxTypes.find((t) => t._id === mid[i].boxType)}
            port={mid[i].port}
            box={mid[i]}
          />
        ) : null}
      </td>
      {right ? (
        <td style={boxStyles(right[i])}>
          {right[i] ? (
            <LockerBox
              boxType={boxTypes.find((t) => t._id === right[i].boxType)}
              number={right[i].port}
              box={right[i]}
            />
          ) : null}
        </td>
      ) : null}
    </tr>
  ))
  return <>{rows}</>
}

export function LocationAllocations({ location }) {
  return (
    <Grid container spacing={2}>
      {!location || !location.controllers
        ? null
        : location.controllers
            .slice()
            .sort((a, b) => (a.mark || '').localeCompare(b.mark || ''))
            .map((controller) => (
              <Grid key={controller.controllerId} sm={6} item>
                <table
                  border="1"
                  cellPadding="10"
                  style={{
                    borderCollapse: 'separate',
                    borderColor: '#bbb',
                    width: '100%',
                  }}
                >
                  <thead>
                    <tr>
                      <th colSpan="3" style={{ borderColor: '#bbb' }}>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                          }}
                        >
                          <b style={{ fontSize: '1.25rem' }}>
                            <Trans
                              i18nKey="lockerAllocations.title"
                              ns="locations"
                              values={{
                                mark: controller.mark,
                                stationVersion:
                                  controller.stationVersion || 'v1',
                              }}
                            />
                          </b>
                          <p style={{ margin: 0, fontWeight: 400 }}>
                            <Trans
                              i18nKey="lockerAllocations.boxId"
                              ns="locations"
                              values={{ boxId: controller.id || 'N/A' }}
                            />
                          </p>
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <Locker
                      boxTypes={location.boxTypes}
                      controller={controller}
                      boxes={location.boxes
                        .filter(
                          (b) => b.controllerId === controller.controllerId
                        )
                        .sort((a, b) => a.port - b.port)}
                    />
                  </tbody>
                </table>
              </Grid>
            ))}
    </Grid>
  )
}
