import React from 'react'
import i18n from 'i18next'
import { initReactI18next, Trans as OriginalTrans } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import sv from './sv'
import en from './en'
import de from './de'

const resources = {
  en,
  sv,
  de,
}

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources,
    fallbackLng: 'en',
    debug: true,
    detection: {
      order: ['querystring', 'cookie', 'localStorage', 'navigator', 'htmlTag'],
      caches: ['localStorage', 'cookie'],
      lookupQuerystring: 'lng',
      lookupCookie: 'i18next',
      lookupLocalStorage: 'i18nextLng',
      htmlTag: document.documentElement,
      checkWhitelist: true,
    },
    interpolation: {
      escapeValue: false,
    },
    format: (value) => {
      if (
        process.env.REACT_APP_LANG_DEBUG === 'true' &&
        typeof value === 'string'
      ) {
        return `T_${value}`
      }
      return value
    },
    components: {},
  })

i18n.on('missingKey', (lngs, namespace, key) => {
  /* eslint-disable-next-line */
  console.error(
    `Missing translation: ${key} in namespace: ${namespace} for language: ${lngs}`
  )
})

const i18nEvents = [
  'initialized',
  'loaded',
  'failedLoading',
  'languageChanged',
  'missingKey',
  'added',
  'removed',
  'addedRemoved',
]
i18nEvents.forEach((event) => {
  i18n.on(event, (arg) => {
    /* eslint-disable-next-line */
    console.log(`i18n ${event}:`, arg)
  })
})

export function Trans({ components, ...props }) {
  return (
    <OriginalTrans
      {...props}
      components={{
        bold: <b />,
        b: <b />,
        ...components,
      }}
    />
  )
}

export default i18n
