import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import Modal from 'react-modal'
import ReactJson from 'react-json-view'
import { useTranslation } from 'react-i18next'
import CloseModalButton from './CloseModalButton'
import { getParcelTraceLogs } from '../redux/actions'

export default function ParcelTraceLogsModal({ closeModal, parcelId }) {
  const dispatch = useDispatch()

  const [traceLogs, setTraceLogs] = useState(null)
  const [showEntryJson, setShowEntryJson] = useState(null)

  const { t } = useTranslation('common')

  useEffect(() => {
    dispatch(getParcelTraceLogs(parcelId)).then((trace) => {
      setTraceLogs(trace.response)
    })
  }, [dispatch, parcelId])

  const createLogEntry = (entry) => {
    const showJson = entry.millis === showEntryJson
    return (
      <div key={entry.millis} className="traceLogBox">
        <div
          className="traceLogMessage"
          onClick={() =>
            setShowEntryJson(
              showEntryJson === entry.millis ? null : entry.millis
            )
          }
        >
          {entry.message}
        </div>
        {showJson ? (
          <div className="traceLogJson">
            <ReactJson
              collapsed={false}
              displayDataTypes={false}
              name={false}
              displayObjectSize={false}
              src={JSON.parse(entry.json)}
            />
          </div>
        ) : null}
      </div>
    )
  }

  const renderTraceLogs = () => {
    if (!traceLogs) return <div>{t('loading')} ...</div>
    if (!traceLogs.length) return <div>{t('noLogs')}</div>

    return traceLogs.map((logArr, i) => {
      if (!logArr.length) return null

      return (
        // eslint-disable-next-line react/no-array-index-key
        <div key={i} style={{ marginBottom: 50 }}>
          <p>
            {t('event')} {i + 1} ({window.niceDate(logArr[0].millis)}):
          </p>
          {logArr.map(createLogEntry)}
        </div>
      )
    })
  }

  return (
    <Modal
      ariaHideApp={false}
      isOpen
      onRequestClose={closeModal}
      style={window.modal.large}
    >
      <CloseModalButton closeModal={closeModal} />

      <br />
      <h3>
        {t('parcelTraceLogs')} {traceLogs ? `(${traceLogs.length})` : null}
      </h3>

      <br />

      {renderTraceLogs()}

      <br />

      <div style={{ width: '30%', marginLeft: '35%' }} />
    </Modal>
  )
}
