import React, { useState, useEffect, useRef } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import TextInputField from '../TextInputField'
import {
  resetFilters,
  updateFilters,
  getParcels,
} from '../../redux/parcels/slice.parcels'
import useEventListener from '../../hooks/useEventListener'
import { useMe } from '../../hooks/useMe'

export default function LegacyParcelsFilters({ isModalOpen = false }) {
  const dispatch = useDispatch()
  const {
    loading,
    filters: { parcelId, flow, daysBack },
  } = useSelector((state) => state.parcels)
  const [shouldFetchParcels, setShouldFetchParcels] = useState(true)
  const { t } = useTranslation('parcels')
  const { user, carrier } = useMe()

  useEventListener(window, 'mousemove', () => {
    if (!user || !carrier || !shouldFetchParcels || isModalOpen || loading)
      return
    if (shouldFetchParcels && !isModalOpen) {
      setShouldFetchParcels(false)
      dispatch(getParcels()).finally(() => {
        setTimeout(() => {
          setShouldFetchParcels(true)
        }, 3000)
      })
    }
  })

  const debounceId = useRef()
  useEffect(() => {
    if (carrier) {
      if (debounceId.current) {
        clearTimeout(debounceId.current)
      }

      debounceId.current = setTimeout(() => {
        dispatch(getParcels())
        debounceId.current = null
      }, 1000)
    }
    return () => {
      if (debounceId.current) {
        clearTimeout(debounceId.current)
        debounceId.current = null
      }
    }
  }, [carrier, daysBack, dispatch, parcelId])

  return (
    <Container fluid>
      <Row
        style={{
          backgroundColor: '#f1f1f1f1',
          borderRadius: 10,
          padding: 10,
          marginBottom: 5,
        }}
      >
        <Col md={4}>
          <div
            style={{
              padding: '12px',
              alignItems: 'center',
            }}
          >
            <div>{t('parcelId')}</div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                margin: '4px 0 8px 0',
              }}
            >
              <div style={{ width: '100%', maxWidth: '300px' }}>
                <TextInputField
                  onChange={(e) =>
                    dispatch(updateFilters({ parcelId: e.target.value }))
                  }
                  value={parcelId}
                  placeHolder={t('searchParcelId')}
                />
              </div>
            </div>
            <button
              type="button"
              onClick={() => dispatch(resetFilters({ flow }))}
            >
              {t('clear')}
            </button>
          </div>
        </Col>
        <Col md={1}>
          <div style={{ marginTop: '50%' }}>
            <span style={{ fontWeight: 'bold' }}>
              {dayjs().subtract(daysBack, 'days').format('YYYY-MM-DD')}
            </span>
          </div>
        </Col>
        <Col md={2}>
          <div style={{ marginTop: '20%' }}>
            <button
              type="button"
              className="ibButton"
              onClick={() =>
                dispatch(updateFilters({ daysBack: daysBack + 1 }))
              }
            >
              {t('prevDay')}
            </button>
          </div>
        </Col>

        <Col md={2}>
          <div style={{ marginTop: '20%' }}>
            <button
              type="button"
              className="ibButton"
              onClick={() =>
                dispatch(updateFilters({ daysBack: daysBack - 1 }))
              }
            >
              {t('nextDay')}
            </button>
          </div>
        </Col>
      </Row>
    </Container>
  )
}
