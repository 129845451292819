/* eslint-disable import/prefer-default-export */
import { useCallback, useState } from 'react'

export const useSimpleForm = ({ fields, onSubmit }) => {
  const [state, setState] = useState(fields)
  const [submitting, setSubmitting] = useState(false)

  const updateFieldValue = useCallback((fieldName, value) => {
    setState((val) => {
      // eslint-disable-next-line no-param-reassign
      val[fieldName] = value

      return { ...val }
    })
  }, [])

  const reset = () => {
    setState({ ...fields })
  }

  return {
    submitting,
    state,
    reset,
    async submit() {
      setSubmitting(true)
      try {
        const updated = Object.keys(fields).reduce(
          (form, field) =>
            state[field] !== fields[field]
              ? { ...form, [field]: state[field] }
              : form,
          {}
        )
        await onSubmit(state, updated)
      } finally {
        setSubmitting(false)
      }
    },
    changed: Object.keys(state).filter((key) => fields[key] !== state[key]),
    fields: Object.keys(state).reduce(
      (form, fieldName) => ({
        ...form,
        [fieldName]: {
          onChange(e) {
            updateFieldValue(fieldName, e?.target?.value ?? e)
          },
          value: state[fieldName],
        },
        readOnly: submitting,
      }),
      {}
    ),
  }
}
