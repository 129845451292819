import React, { useState, useEffect } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { getMe } from './redux/actions'
import { fetcher } from './utils/fetcher'
import swaler from './utils/swaler'
import TextInputField from './common/TextInputField'

export default function Login() {
  const [credentials, setCredentials] = useState({
    username: '',
    password: '',
  })
  const { t } = useTranslation('login')
  const history = useHistory()

  const submitLogin = async () => {
    const { error, response } = await fetcher('/login', {
      method: 'POST',
      body: JSON.stringify(credentials),
    })
    if (error) swaler.warn(error)
    else {
      localStorage.jwt = response.jwt
      history.push(`/user`)
    }
  }

  const updateInput = ({ target }) => {
    setCredentials({ ...credentials, [target.name]: target.value })
  }

  const handleKeyDown = ({ code }) => {
    if (code === 'Enter') submitLogin()
  }

  const dispatch = useDispatch()

  useEffect(() => {
    if (localStorage.jwt) {
      dispatch(getMe()).then(({ error }) => {
        if (!error) {
          history.push('/user')
        } else {
          localStorage.removeItem('jwt')
        }
      })
    }
  }, [dispatch, history])

  return (
    <div
      style={{
        marginTop: '10%',
        padding: '10%',
        border: '1px solid lightgrey',
        width: '60%',
        height: '45%',
        borderRadius: 10,
        marginLeft: 'auto',
        marginRight: 'auto',
        backgroundColor: '#f1f1f1',
      }}
    >
      <Container>
        <Row>
          <Col {...window.bCol()}>{/* Loggain */}</Col>
        </Row>
        <br />
        <Row>
          <Col {...window.bCol(6)}>
            <TextInputField
              placeHolder={t('username')}
              name="username"
              value={credentials.username}
              onChange={updateInput}
              onKeyPress={handleKeyDown}
            />
          </Col>
          <Col {...window.bCol(6)}>
            <TextInputField
              placeHolder={t('password')}
              name="password"
              type="password"
              value={credentials.password}
              onChange={updateInput}
              onKeyPress={handleKeyDown}
            />
          </Col>
        </Row>
        <br />
        <br />
        <Row>
          <Col {...window.bCol(12)} style={{ textAlign: 'left' }}>
            <div className="ibButton" onClick={submitLogin}>
              {t('login')}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}
