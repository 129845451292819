import { configureStore } from '@reduxjs/toolkit'
import root from './reducers'
import parcelsReducer from './parcels/slice.parcels'
import usersReducer from './users/slice.users'
import carriersReducer from './carriers/slice.carriers'
import locationsReducer from './locations/slice.locations'

export default configureStore({
  reducer: {
    root,
    parcels: parcelsReducer,
    users: usersReducer,
    carriers: carriersReducer,
    locations: locationsReducer,
  },
})
