import qs from 'qs'

function updateOptions(options) {
  const update = { ...options }

  const headers = {}
  Object.entries(options.headers || {}).forEach(([key, val]) => {
    if (val !== undefined && val !== null) {
      headers[key] = val
    }
  })
  update.headers = {
    'Content-Type': 'application/json',
    ...headers,
  }

  if (localStorage.jwt) {
    update.headers.Authorization = `Bearer ${localStorage.jwt}`
  }

  update.cache = 'no-store'

  return update
}

export const fetcher = async (path, options) => {
  const url = `${window.backendUrl}${path}`
  const result = await fetch(url, updateOptions(options))

  if (result.status === 401 && window.location.pathname !== '/login') {
    window.location = '/login'
  }

  if (!result.ok) {
    const error = await result.text()
    return { error, status: result.status }
  }
  if (result.headers.get('content-type')?.includes('application/json')) {
    const response = await result.json()
    return { response, status: result.status }
  }
  return { response: await result.text(), status: result.status }
}

export class ApiError extends Error {
  constructor(message, status) {
    super(message)
    this.name = 'ApiError'
    this.status = status
  }
}

export default async function request(
  method,
  urlPath,
  body = {},
  options = {}
) {
  const opt = { ...options, method }
  let query = urlPath.split('?')[1] || ''
  const isGET = method.toUpperCase() === 'GET'
  if (!isGET) {
    opt.body = JSON.stringify(body)
  } else {
    query = `?${qs.stringify({
      ...qs.parse(query),
      ...body,
    })}`
  }
  const { error, response, status } = await fetcher(
    `${encodeURI(urlPath)}${query}`,
    opt
  )
  if (error) {
    throw new ApiError(error, status)
  }
  return response
}
