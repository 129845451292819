import React from 'react'
import { useSelector } from 'react-redux'
import ParcelsView from './ParcelsView'
import LegacyParcelsView from './LegacyParcelsView'

export default function RegisteredAllocatedParcelsView({ isModalOpen }) {
  const legacyView = useSelector((state) => state.root.legacyView)

  return (
    <div>
      {legacyView ? (
        <LegacyParcelsView isModalOpen={isModalOpen} />
      ) : (
        <ParcelsView />
      )}
    </div>
  )
}
