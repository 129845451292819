const THEME = {
  delivery: {
    primary: '#e8e0d8',
    secondary: '#90a0a8',
    dark: '#005060',
    color: 'white',
  },
  pickup: {
    primary: '#90a0a8',
    secondary: '#e8e0d8',
    dark: '#655d56',
    color: 'black',
  },
}

// eslint-disable-next-line import/prefer-default-export
export const theme = (parcelFlow) => {
  return parcelFlow === 'pickup' ? THEME.pickup : THEME.delivery
}
