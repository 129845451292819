module.exports = {
  all: [
    'created',
    'allocated',
    'on-way',
    'in-box',
    'collected',
    'marked-for-return',
    'driver-picked-up',
    'cancelled',
    'awaiting-period',
    'pickup-created',
    'pickup-on-way',
    'pickup-in-box',
    'pickup-ready-to-collect',
    'pickup-collected',
  ],
  appendable: {
    delivery: ['on-way', 'in-box', 'marked-for-return', 'cancelled'],
    pickup: [
      'pickup-on-way',
      'pickup-in-box',
      'pickup-ready-to-collect',
      'cancelled',
    ],
  },
  messageStatuses: [
    'on-way',
    'in-box',
    'parcel-pickup-reminder',
    'marked-for-return',
  ],
  translations: {
    created: 'Skapad hos iBoxen',
    allocated: 'Allokerad till ett fack',
    'on-way': 'På väg till ett paketskåp',
    'in-box': 'Levererad till paketskåp',
    'marked-for-return': 'Markerad som retur',
    'driver-picked-up': 'Upphämtad av transportör',
    collected: 'Upphämtad av kund',
    cancelled: 'Avbruten',
    'awaiting-period': 'Inväntar period',
    deviation: 'Avvikelse',
    finished: 'Avslutade',
    'parcel-pickup-reminder': 'Påminnelse hämta upp paket',
    'message-descriptions': {
      'marked-for-return': 'Paketet har gått över liggtiden',
    },
    'pickup-created': 'Skapad hos iBoxen',
    'pickup-on-way': 'På väg till ett paketskåp',
    'pickup-in-box': 'Levererad till paketskåp',
    'pickup-ready-to-collect': 'Redo att hämta',
    'pickup-collected': 'Upphämtad',
  },
}
