import React from 'react'
import { useTranslation } from 'react-i18next'

export default function CloseModalButton({ closeModal, text }) {
  const { t } = useTranslation('common')
  /* eslint-disable-next-line */
  if (!text) text = t('close')
  return (
    <div style={{ width: '100%', height: 30 }}>
      <button
        type="button"
        style={{ position: 'absolute', right: 15 }}
        className="ibButton ibSmallWarn"
        onClick={closeModal}
      >
        {text}
      </button>
    </div>
  )
}
