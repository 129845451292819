const password = (value) => {
  const hasUppercase = /[A-Z]/.test(value)
  const hasLowercase = /[a-z]/.test(value)
  const hasNumber = /[0-9]/.test(value)
  const hasSpecialChar = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(value)
  const isLongEnough = value?.length >= 8

  return (
    hasUppercase && hasLowercase && hasNumber && hasSpecialChar && isLongEnough
  )
}

export default { password }
