import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import {
  isSuperUser,
  userIsAllowedTo,
  carrierIsAllowedTo,
} from '../utils/permissions'

export const useMe = () => {
  const { user, carrier } = useSelector((state) => state.root)

  return useMemo(
    () => ({
      user: {
        ...user,
        allowedTo: user?.role?.allowedTo,
        isAllowedTo: (action) => userIsAllowedTo(user, action),
        isSuperUser: isSuperUser(user),
      },
      carrier: {
        ...carrier,
        isAllowedTo: (action) => carrierIsAllowedTo(carrier, action),
      },
    }),
    [user, carrier]
  )
}
