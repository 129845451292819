import React from 'react'

export default function NumberFilter({ column: { filterValue, setFilter } }) {
  return (
    <span>
      <input
        type="number"
        className="textInput"
        value={filterValue || ''}
        onChange={(e) => {
          setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
        }}
      />
    </span>
  )
}
