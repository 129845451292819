import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableSortLabel,
} from '@mui/material'
import { visuallyHidden } from '@mui/utils'
import dayjs from 'dayjs'
import {
  getUsers,
  setSorting,
  setPagination,
  deleteUser,
} from '../../../redux/users/slice.users'
import PaginationToolbar from '../../../shared/PaginationToolbar'
import { Line } from '../../../shared/Line'
import swaler from '../../../utils/swaler'

export const formatDateTime = (dateString) => {
  if (!dateString) return ''
  const date = dayjs(dateString)
  return date.format('L LT')
}

export function ListUsers({ roles, onNew, onEdit }) {
  const { t } = useTranslation('users')
  const dispatch = useDispatch()
  const { data, cache, sorting, pagination } = useSelector(
    (state) => state.users
  )
  const [confirmDeleteUser, setConfirmDeleteUser] = useState(null)

  const columns = useMemo(
    () => [
      {
        id: 'username',
        type: String,
        label: t('username', { ns: 'common' }),
        editable: true,
      },
      {
        id: 'lastActive',
        type: Date,
        label: t('lastActive', { ns: 'common' }),
        editable: false,
      },
      {
        id: 'role',
        type: String,
        label: t('role', { ns: 'common' }),
        editable: true,
        render: (row) => roles.find((r) => r._id === row.role)?.niceName,
      },
      {
        id: 'actions',
        render: (row) => (
          <>
            <Button
              color="iboxen"
              onClick={() => {
                onEdit(row)
              }}
            >
              {t('Edit', { ns: 'common' })}
            </Button>
            <Button
              color="error"
              onClick={() => {
                setConfirmDeleteUser(row)
              }}
            >
              {t('delete', { ns: 'common' })}
            </Button>
          </>
        ),
      },
    ],
    [t, roles, onEdit]
  )

  const toolbar = (
    <PaginationToolbar
      spacing={0.5}
      count={data.metadata?.totalCount || 0}
      rowsPerPageOptions={[10, 20, 30, 40]}
      rowsPerPage={pagination.perPage}
      page={pagination.page}
      onPageChange={(_, page) => {
        dispatch(setPagination({ page }))
        dispatch(getUsers())
      }}
      onRowsPerPageChange={(e) => {
        dispatch(setPagination({ perPage: parseInt(e.target.value, 10) }))
        dispatch(getUsers())
      }}
    />
  )

  return (
    <>
      <Box sx={{ p: 2, pt: 0 }}>
        <Button variant="contained" onClick={() => onNew()}>
          {t('createUser')}
        </Button>
      </Box>
      {toolbar}
      <Line mt={0.5} mb={0} />
      <TableContainer sx={{ pb: 0.5 }}>
        <Table size="small" sx={{ tableLayout: 'fixed' }}>
          <TableHead>
            <TableRow sx={{ bgcolor: 'rgba(241, 241, 241, 0.745)' }}>
              {columns.map((column) => (
                <TableCell
                  component="th"
                  key={column.id}
                  align={column.type === Number ? 'right' : 'left'}
                >
                  {column.sortable ? (
                    <TableSortLabel
                      active={sorting.orderBy === column.id}
                      direction={
                        sorting.orderBy === column.id
                          ? sorting.order
                          : undefined
                      }
                      onClick={() => {
                        let order = 'desc'
                        let orderBy = column.id
                        if (
                          sorting.orderBy === column.id &&
                          sorting.order === 'asc'
                        ) {
                          order = undefined
                          orderBy = undefined
                        } else if (sorting.orderBy === column.id) {
                          order = 'asc'
                        }
                        dispatch(setSorting({ orderBy, order }))
                        dispatch(getUsers())
                      }}
                    >
                      {column.label}
                      {sorting.orderBy === column.id ? (
                        <Box component="span" sx={visuallyHidden}>
                          {sorting.order === 'desc'
                            ? t('sortedDescending', { ns: 'common' })
                            : t('sortedAscending', { ns: 'common' })}
                        </Box>
                      ) : null}
                    </TableSortLabel>
                  ) : (
                    t(column.label)
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.users
              .map(({ _id }) => cache[_id])
              .map((user) => ({
                ...user,
                lastActive: formatDateTime(user.lastActive),
              }))
              .map((user, i) => (
                <TableRow
                  tabIndex={-1}
                  key={user._id}
                  sx={{ bgcolor: i % 2 && 'rgba(241, 241, 241, 0.745)' }}
                >
                  {columns.map((column) => (
                    <TableCell
                      key={`${user._id}-${column.id}`}
                      align={column.type === Number ? 'right' : 'left'}
                      sx={column.sx}
                    >
                      {typeof column.render === 'function'
                        ? column.render(user)
                        : user[
                            typeof column.selector === 'function'
                              ? column.selector(user)
                              : column.selector || column.id
                          ]}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      {toolbar}

      <Dialog
        open={!!confirmDeleteUser}
        onClose={() => setConfirmDeleteUser(null)}
      >
        <DialogTitle>{t('deleteUserAlertTitle')}</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ whiteSpace: 'pre' }}>
            {t('deleteUserAlertContent', {
              username: confirmDeleteUser?.username,
            })}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmDeleteUser(null)}>
            {t('cancel', { ns: 'common' })}
          </Button>
          <Button
            onClick={() => {
              dispatch(deleteUser({ id: confirmDeleteUser?._id })).then(
                ({ error }) => {
                  if (!error) {
                    swaler.success('User deleted')
                    dispatch(getUsers())
                  }
                }
              )
              setConfirmDeleteUser(null)
            }}
            autoFocus
            color="error"
          >
            {t('delete', { ns: 'common' })}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
