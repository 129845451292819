/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'
import getCarriers from './async.getCarriers'

export const initialState = {
  loading: false,
  error: undefined,
  cache: {},
  data: {
    carriers: [],
    metadata: {},
  },
  filters: {},
  sorting: { orderBy: undefined, order: undefined },
  pagination: { page: 0, perPage: undefined },
}

export const carriersSlice = createSlice({
  name: 'carriers',
  initialState,
  reducers: {
    resetFilters(state, action) {
      state.filters = { ...initialState.filters, ...action.payload }
    },
    updateFilters(state, action) {
      state.filters = {
        ...initialState.filters,
        ...state.filters,
        ...action.payload,
      }
    },
    setPagination(state, action) {
      state.pagination = {
        ...state.pagination,
        ...action.payload,
      }
    },
    setSorting(state, action) {
      state.sorting = {
        ...state.sorting,
        ...action.payload,
      }
    },
    cacheCarriers(state, action) {
      action.payload.carriers.forEach((carrier) => {
        state.cache[carrier._id] = carrier
      })
    },
    updateCarrier(state, action) {
      state.cache[action.payload._id] = action.payload
    },
  },
  extraReducers(builder) {
    builder
      // getCarriers
      .addCase(getCarriers.pending, (state) => {
        state.loading = true
      })
      .addCase(getCarriers.rejected, (state, action) => {
        if (action.error.name !== 'AbortError') {
          state.error = action.payload ?? action.error
        }
      })
      .addCase(getCarriers.fulfilled, (state, action) => {
        state.error = undefined
        const carriers = []
        action.payload.carriers.forEach((carrier) => {
          carrier.$cached = new Date().getTime()
          state.cache[carrier._id] = carrier
          carriers.push(state.cache[carrier._id])
        })
        state.data = {
          ...action.payload,
          carriers,
        }
      })
      // Matchers
      // getCarriers
      .addMatcher(getCarriers.settled, (state) => {
        state.loading = false
      })
  },
})

// Action creators are generated for each case reducer function
export const {
  resetFilters,
  updateFilters,
  setPagination,
  setSorting,
  cacheCarriers,
} = carriersSlice.actions

export { getCarriers }

export default carriersSlice.reducer
