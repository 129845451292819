/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'
import getUsers from './async.getUsers'
import putUser from './async.putUser'
import deleteUser from './async.deleteUser'
import getRoles from './async.getRoles'

export const initialState = {
  loading: false,
  error: undefined,
  cache: {},
  data: {
    users: [],
    metadata: {},
  },
  filters: {
    username: undefined,
    lastActive: undefined,
  },
  sorting: { orderBy: undefined, order: undefined },
  pagination: { page: 0, perPage: 20 },
}

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    resetFilters(state, action) {
      state.filters = { ...initialState.filters, ...action.payload }
    },
    updateFilters(state, action) {
      state.filters = {
        ...initialState.filters,
        ...state.filters,
        ...action.payload,
      }
    },
    setPagination(state, action) {
      state.pagination = {
        ...state.pagination,
        ...action.payload,
      }
    },
    setSorting(state, action) {
      state.sorting = {
        ...state.sorting,
        ...action.payload,
      }
    },
    cacheUsers(state, action) {
      action.payload.users.forEach((user) => {
        state.cache[user._id] = user
      })
    },
    updateUser(state, action) {
      state.cache[action.payload._id] = action.payload
    },
  },
  extraReducers(builder) {
    builder
      // getUsers
      .addCase(getUsers.pending, (state) => {
        state.loading = true
      })
      .addCase(getUsers.rejected, (state, action) => {
        if (action.error.name !== 'AbortError') {
          state.error = action.payload ?? action.error
        }
      })
      .addCase(getUsers.fulfilled, (state, action) => {
        state.error = undefined
        const users = []
        action.payload.users.forEach((user) => {
          user.$cached = new Date().getTime()
          state.cache[user._id] = user
          users.push(state.cache[user._id])
        })
        state.data = {
          ...action.payload,
          users,
        }
      })
      .addCase(putUser.pending, (state) => {
        state.loading = true
      })
      .addCase(putUser.rejected, (state, action) => {
        if (action.error.name !== 'AbortError') {
          state.error = action.payload ?? action.error
        }
      })
      .addCase(putUser.fulfilled, (state, action) => {
        state.error = undefined
        const user = action.payload
        user.$cached = new Date().getTime()
        state.cache[user._id] = user
        const existingUser = state.data.users.find((u) => u._id === user._id)
        if (existingUser) {
          state.data.users = state.data.users.map((u) => {
            if (u._id === user._id) {
              return user
            }
            return u
          })
        } else {
          state.data.users.push(user)
        }
      })
      .addCase(deleteUser.pending, (state) => {
        state.loading = true
      })
      .addCase(deleteUser.rejected, (state, action) => {
        if (action.error.name !== 'AbortError') {
          state.error = action.payload ?? action.error
        }
      })
      .addCase(deleteUser.fulfilled, (state, action) => {
        state.error = undefined
        delete state.cache[action.meta.arg.id]
        const existingUser = state.data.users.find(
          (u) => u._id === action.meta.arg.id
        )
        if (existingUser) {
          state.data.users = state.data.users.filter(
            (u) => u._id !== action.meta.arg.id
          )
        }
      })
      // Matchers
      // getUsers
      .addMatcher(getUsers.settled, (state) => {
        state.loading = false
      })
  },
})

// Action creators are generated for each case reducer function
export const {
  resetFilters,
  updateFilters,
  setPagination,
  setSorting,
  cacheUsers,
} = usersSlice.actions

export { getUsers, putUser, deleteUser, getRoles }

export default usersSlice.reducer
