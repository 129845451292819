import React, { useState } from 'react'
import {
  Typography,
  Grid,
  TextField,
  MenuItem,
  FormControlLabel,
  Select,
  Popover,
  IconButton,
  Stack,
} from '@mui/material'
import FirstPageIcon from '@mui/icons-material/FirstPage'
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight'
import LastPageIcon from '@mui/icons-material/LastPage'
import SettingsIcon from '@mui/icons-material/Settings'
import { useTranslation } from 'react-i18next'

const cleanInt = (v) => parseInt(String(v).replace(/[^\d]/g, ''), 10)

export default function PaginationToolbar({
  spacing,
  rowsPerPageOptions = [10, 20, 30, 40],
  count,
  rowsPerPage,
  page,
  onPageChange,
  onRowsPerPageChange,
  settings,
}) {
  const [optionsAnchor, setOptionsAnchor] = useState(null)

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0)
  }

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1)
  }

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1)
  }

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1))
  }

  const { t } = useTranslation('parcels')

  return (
    <Grid alignItems="center" spacing={spacing} px={spacing} container>
      <Grid item xs={3} md={4} display="flex" alignItems="center">
        <FormControlLabel
          label={`${t('rowsPerPage')}:`}
          labelPlacement="start"
          control={
            <Select
              value={rowsPerPage}
              onChange={onRowsPerPageChange}
              size="small"
              sx={{ ml: 1 }}
              slotProps={{ input: { sx: { pl: 1, py: 0.7 } } }}
            >
              {rowsPerPageOptions.map((o) => (
                <MenuItem key={o} value={o}>
                  {o}
                </MenuItem>
              ))}
            </Select>
          }
          sx={{ display: { xs: 'none', md: 'flex' }, mb: 0, mr: 0 }}
        />
        <Typography my={0} pl={1}>
          {page * rowsPerPage + 1} - {Math.min(count, (page + 1) * rowsPerPage)}{' '}
          / {count}
        </Typography>
      </Grid>
      <Grid
        item
        xs={6}
        md={4}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          <FirstPageIcon />
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          <KeyboardArrowLeft />
        </IconButton>

        <TextField
          value={page + 1}
          onChange={(e) => {
            const newPage = (cleanInt(e.target.value) || 1) - 1
            if (page !== newPage) {
              onPageChange(e, newPage)
            }
          }}
          size="small"
          InputProps={{
            max: Math.ceil(count / rowsPerPage),
            min: 1,
            sx: { minWidth: 35, maxWidth: 35 },
            inputProps: { sx: { textAlign: 'center', px: 0, py: 0.7 } },
          }}
        />

        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          <KeyboardArrowRight />
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          <LastPageIcon />
        </IconButton>
      </Grid>
      <Grid
        item
        xs={3}
        md={4}
        order={{ xs: 1, md: 1 }}
        display="flex"
        justifyContent="flex-end"
      >
        <IconButton
          onClick={(e) => setOptionsAnchor(e.target)}
          sx={{ display: !settings && { md: 'none' } }}
        >
          <SettingsIcon />
        </IconButton>
        <Popover
          open={Boolean(optionsAnchor)}
          anchorEl={optionsAnchor}
          onClose={() => setOptionsAnchor(null)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          disableScrollLock
        >
          <Stack p={2} spacing={2}>
            <FormControlLabel
              label={`${t('rowsPerPage')}:`}
              labelPlacement="start"
              control={
                <Select
                  value={rowsPerPage}
                  onChange={onRowsPerPageChange}
                  select
                  size="small"
                  sx={{ ml: 1 }}
                >
                  {rowsPerPageOptions.map((o) => (
                    <MenuItem key={o} value={o}>
                      {o}
                    </MenuItem>
                  ))}
                </Select>
              }
              sx={{ mb: 0, display: { md: 'none' } }}
            />
            {settings}
          </Stack>
        </Popover>
      </Grid>
    </Grid>
  )
}
