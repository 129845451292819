import { createAsyncThunk } from '@reduxjs/toolkit'
import i18n from '../../lang/i18n'
import swaler from '../../utils/swaler'
import request from '../../utils/fetcher'

const transformUserData = (user) => ({
  ...user,
  role:
    user.role && (typeof user.role === 'string' ? user.role : user.role._id),
})

let ac

export default createAsyncThunk('putUser', async (payload, thunkApi) => {
  if (ac) {
    try {
      ac.abort()
    } catch {
      // Silent ignore
    }
  }
  ac = new AbortController()
  const state = thunkApi.getState()
  try {
    if (payload._id) {
      return await request(
        'PATCH',
        `/users/${payload._id}`,
        transformUserData(payload),
        {
          signal: ac.signal,
          headers: { 'x-iboxen-carrier': state.root.carrierHeader },
        }
      )
    }
    return await request('POST', '/users/new', transformUserData(payload), {
      signal: ac.signal,
      headers: { 'x-iboxen-carrier': state.root.carrierHeader },
    })
  } catch (e) {
    if (e.name !== 'AbortError') {
      let { message } = e
      if (i18n.exists(`responseMessages:${message.replaceAll(' ', '-')}`)) {
        message = i18n.t(`responseMessages:${message.replaceAll(' ', '-')}`)
      }
      swaler.error(message)
    }
    throw e
  } finally {
    ac = undefined
  }
})
