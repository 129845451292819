/* eslint-disable import/no-import-module-exports */
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import 'dayjs/locale/sv'

import App from './App'
import store from './redux/store'

import './index.css'

dayjs.locale('sv')

window.store = store

function AppInit() {
  ReactDOM.render(
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="sv">
      <Provider store={window.store}>
        <App />
      </Provider>
    </LocalizationProvider>,
    document.getElementById('root')
  )
}
AppInit()

if (module.hot) {
  module.hot.accept('./App', AppInit)
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals()
