import React from 'react'

export default function SortableColumn({ column, children }) {
  const { canSort, getSortByToggleProps, isSorted, isSortedDesc } = column
  return (
    <div
      {...(canSort
        ? getSortByToggleProps({
            style: {
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            },
          })
        : {})}
    >
      {children}
      {canSort && (
        <span
          style={{
            display: 'inline-block',
            marginLeft: 5,
            fontSize: 10,
            lineHeight: 1,
            color: 'gray',
          }}
        >
          {isSorted && isSortedDesc ? <>&nbsp;</> : '▲'}
          <br />
          {isSorted && !isSortedDesc ? <>&nbsp;</> : '▼'}
        </span>
      )}
    </div>
  )
}
