const swal = require('sweetalert2')

export default {
  warn: (title, text = '') => swal.fire({ title, text }),
  success: (title, text, options) =>
    swal.fire({
      title,
      text,
      position: 'center',
      icon: 'success',
      showConfirmButton: true,
      ...options,
    }),
  confirm: (text) =>
    swal.fire({ text, showCancelButton: true, showConfirmButton: true }),
  error: (title, text, options) =>
    swal.fire({
      title,
      text,
      position: 'center',
      icon: 'error',
      showConfirmButton: true,
      ...options,
    }),
}
