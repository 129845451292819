import React, { useState, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'

let autoComplete

const loadScript = (url, callback) => {
  if (window.google && window.google.maps) {
    callback()
    return
  }

  const script = document.createElement('script')
  script.type = 'text/javascript'
  script.onload = () => callback()
  script.src = url

  document.getElementsByTagName('head')[0].appendChild(script)
}

const handlePlaceSelect = async (updateQuery) => {
  const addressObject = autoComplete.getPlace()
  if (!addressObject) return
  const formattedAddress = addressObject.formatted_address
  const valuesToGrab = {
    street_number: '',
    route: '',
    postal_town: '',
    postal_code: '',
    locality: '',
  }

  if (!addressObject.address_components) return

  addressObject.address_components.forEach((addressComponent) => {
    const type = addressComponent.types[0]

    if (Object.prototype.hasOwnProperty.call(valuesToGrab, type)) {
      valuesToGrab[type] = addressComponent.long_name
    }
  })

  updateQuery({
    formattedAddress,
    city: valuesToGrab.postal_town || valuesToGrab.locality,
    zip: valuesToGrab.postal_code.split(' ').join(''),
    street: `${valuesToGrab.route} ${valuesToGrab.street_number}`,
  })
}

const handleScriptLoad = (updateQuery, autoCompleteRef) => {
  autoComplete = new window.google.maps.places.Autocomplete(
    autoCompleteRef.current,
    {
      types: ['address'],
      componentRestrictions: { country: ['se', 'fi', 'de'] },
      language: 'sv',
    }
  )
  autoComplete.setFields(['address_components', 'formatted_address'])
  autoComplete.addListener('place_changed', () =>
    handlePlaceSelect(updateQuery)
  )
}

function SearchLocationInput({ onChange }) {
  const [query, setQuery] = useState({
    formattedAddress: '',
    steet: '',
    city: '',
    zip: '',
  })
  const autoCompleteRef = useRef(null)

  const onInputChange = (event) => {
    setQuery({
      steet: '',
      city: '',
      zip: '',
      formattedAddress: event.target.value,
    })
    onChange(query)
  }

  useEffect(() => {
    loadScript(
      `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&libraries=places`,
      () =>
        handleScriptLoad((value) => {
          setQuery(value)
          onChange(value)
        }, autoCompleteRef)
    )
  }, [onChange])

  const { t } = useTranslation('common')

  return (
    <input
      // eslint-disable-next-line jsx-a11y/no-autofocus
      autoFocus
      className="google-location-input"
      ref={autoCompleteRef}
      onChange={onInputChange}
      placeholder={t('searchLocation')}
      value={query.formattedAddress}
      autoComplete="off"
    />
  )
}

export default SearchLocationInput
