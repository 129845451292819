import React, { useState, useEffect, useCallback } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import Modal from 'react-modal'
import { useDispatch } from 'react-redux'
import swal from 'sweetalert2'
import { useTranslation } from 'react-i18next'
import * as actions from '../../redux/actions'
import CloseModalButton from '../CloseModalButton'
import LocationsMap from './Map/LocationsMap'
import LocationsTable from './LocationsTable'

export default function LocationsModal({ closeModal }) {
  const [listStyle, setListStyle] = useState('list')
  const [locations, setLocations] = useState()
  const [agentLocations, setAgentLocations] = useState([])
  const [mapPosition, setMapPosition] = useState()
  const dispatch = useDispatch()
  const { t } = useTranslation('locations')

  useEffect(() => {
    dispatch(actions.getLocations()).then(({ response }) =>
      setLocations(response)
    )
    dispatch(actions.getAgentLocations()).then(({ response }) =>
      setAgentLocations(response)
    )
  }, [dispatch])

  const updateLocation = useCallback(
    async (_id, data) => {
      try {
        await dispatch(actions.updateLocation(_id, data))
        const location = locations.find((loc) => loc._id === _id)
        locations[locations.indexOf(location)] = {
          ...location,
          ...data,
        }
        setLocations([...locations])
      } catch (e) {
        /* eslint-disable-next-line */
        console.log(e)
      }
    },
    [dispatch, locations]
  )

  const updateLocations = useCallback(
    async (locationsData) => {
      try {
        const { response } = await dispatch(
          actions.updateLocations(locationsData)
        )
        if (response) {
          response.succeded.forEach((_id) => {
            const location = locations.find((loc) => loc._id === _id)
            locations[locations.indexOf(location)] = {
              ...location,
              ...locationsData.find((data) => data._id === _id),
            }
          })
          if (response.failed.length > 0) {
            swal.fire({
              position: 'center',
              icon: 'error',
              title: t('failedUpdatingLocations', {
                count: response.failed.length,
              }),
              showConfirmButton: true,
            })
          }
          setLocations([...locations])
        }
      } catch (e) {
        /* eslint-disable-next-line */
        console.log(e)
      }
    },
    [dispatch, locations, t]
  )

  return (
    <Modal
      ariaHideApp={false}
      isOpen
      onRequestClose={closeModal}
      style={window.modal.large}
    >
      <Container fluid>
        <Row
          style={{
            position: listStyle === 'map' ? 'relative' : undefined,
            zIndex: 1000000,
            marginBottom: 15,
          }}
        >
          <Col {...window.bCol(2)}>
            <button
              type="button"
              className="ibButton"
              onClick={() => setListStyle('list')}
            >
              {t('list')}
            </button>
          </Col>
          <Col {...window.bCol(2)}>
            <button
              type="button"
              className="ibButton"
              onClick={() => setListStyle('map')}
            >
              {t('map')}
            </button>
          </Col>
          <Col className="pull-right">
            <CloseModalButton text="X" closeModal={closeModal} />
          </Col>
        </Row>
        {listStyle === 'list' && (
          <LocationsTable
            locations={locations || []}
            updateLocation={updateLocation}
            updateLocations={updateLocations}
            showOnMap={(location) => {
              setMapPosition(location.coordinates)
              setListStyle('map')
            }}
          />
        )}
        {listStyle === 'map' && (
          <LocationsMap
            locations={locations}
            agentLocations={agentLocations}
            updateLocation={updateLocation}
            position={mapPosition}
            setPosition={setMapPosition}
          />
        )}
      </Container>
    </Modal>
  )
}
