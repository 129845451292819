import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react'
import { Form, Spinner, Dropdown, Button } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import DataTable from '../Table'
import { ReactComponent as MapIcon } from './map-icon.svg'

function AsyncToggle({ id, checked, onChange }) {
  const [loading, setLoading] = useState(false)

  const onToggle = useCallback(
    async (e) => {
      setLoading(true)
      try {
        await onChange(e.target.checked)
      } catch (error) {
        // empty
      }
      setLoading(false)
    },
    [onChange]
  )

  if (loading) {
    return <Spinner animation="border" size="sm" />
  }
  return (
    <Form.Check
      type="switch"
      id={`active-switch-${id}`}
      checked={checked}
      onChange={onToggle}
    />
  )
}

function ToggleActiveFilter({ column: { filterValue, setFilter } }) {
  const ref = useRef()
  useEffect(() => {
    if (filterValue === undefined) {
      ref.current.checked = undefined
      ref.current.indeterminate = true
    } else {
      ref.current.checked = filterValue
    }
  }, [filterValue])
  const onChange = useCallback(() => {
    if (filterValue === undefined) {
      setFilter(true)
    } else {
      setFilter(filterValue ? false : undefined)
    }
  }, [filterValue, setFilter])
  return (
    <span>
      <input ref={ref} type="checkbox" onChange={onChange} />
    </span>
  )
}

function toggleActiveFilterFn(rows, id, filterValue) {
  if (filterValue === null) {
    return rows
  }
  return rows.filter((row) => row.original.carrierActive === filterValue)
}

export default function LocationsTable({
  locations,
  updateLocation,
  updateLocations,
  showOnMap,
}) {
  const [bulkSelected, setBulkSelected] = useState([])
  const { t } = useTranslation('locations')
  const BulkSelectRow = useCallback(
    ({ row }) => {
      const isSelected = bulkSelected.indexOf(row.original._id) >= 0
      const onChange = () => {
        if (isSelected) {
          bulkSelected.splice(bulkSelected.indexOf(row.original._id), 1)
        } else {
          bulkSelected.push(row.original._id)
        }
        setBulkSelected([...bulkSelected])
      }
      return <input type="checkbox" onChange={onChange} checked={isSelected} />
    },
    [bulkSelected]
  )
  const BulkSelectAll = useCallback(
    ({ filteredRows }) => {
      const allSelected = bulkSelected.length === filteredRows.length
      const bulkActivate = () => {
        updateLocations(
          bulkSelected.map((_id) => ({ _id, carrierActive: true }))
        )
      }
      const bulkDeactive = () => {
        updateLocations(
          bulkSelected.map((_id) => ({ _id, carrierActive: false }))
        )
      }
      const bulkToggle = () => {
        setBulkSelected(
          allSelected ? [] : filteredRows.map((row) => row.original._id)
        )
      }
      return (
        <>
          {bulkSelected.length > 0 && (
            <Dropdown>
              <Dropdown.Toggle id="bulk-action">&#8285;</Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={bulkActivate}>
                  {t('activateLocations', { count: bulkSelected.length })}
                </Dropdown.Item>
                <Dropdown.Item onClick={bulkDeactive}>
                  {t('deactivateLocations', { count: bulkSelected.length })}
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          )}
          <input type="checkbox" onChange={bulkToggle} checked={allSelected} />
        </>
      )
    },
    [bulkSelected, updateLocations, t]
  )

  const ToggleActive = useCallback(
    ({ row }) => (
      <AsyncToggle
        id={row.original._id}
        onChange={(checked) =>
          updateLocation(row.original._id, { carrierActive: checked })
        }
        checked={row.original.carrierActive}
      />
    ),
    [updateLocation]
  )

  const ShowOnMap = useCallback(
    ({ row }) => (
      <Button
        size="sm"
        title={t('showOnMap')}
        onClick={() => showOnMap(row.original)}
      >
        <MapIcon />
      </Button>
    ),
    [showOnMap, t]
  )

  const columns = useMemo(
    () => [
      {
        Header: BulkSelectAll,
        id: 'bulk',
        Cell: BulkSelectRow,
        width: 30,
      },
      {
        Header: t('active'),
        id: 'active',
        Cell: ToggleActive,
        defaultCanFilter: true,
        Filter: ToggleActiveFilter,
        filter: toggleActiveFilterFn,
        width: 65,
      },
      {
        Header: t('street'),
        id: 'street',
        accessor: (l) => l.address.street,
        Filter: 'text',
        filter: 'includes',
      },
      {
        Header: t('zip'),
        id: 'zip',
        accessor: (l) => String(l.address.zip),
        Filter: 'text',
        filter: 'includes',
        width: 100,
      },
      {
        Header: t('city'),
        id: 'city',
        accessor: (l) => l.address.city,
        Filter: 'text',
        filter: 'includes',
      },
      {
        Header: t('numberOfLockers'),
        id: 'controllers',
        accessor: (l) => l.controllers,
        Filter: 'advancedNumber',
        filter: 'advancedNumber',
        min: 1,
        width: 100,
      },
      {
        Header: '',
        id: 'show-on-map',
        Cell: ShowOnMap,
        width: 40,
      },
    ],
    [BulkSelectAll, BulkSelectRow, ShowOnMap, ToggleActive, t]
  )

  const getRowId = useCallback((row) => row._id, [])

  return (
    <DataTable columns={columns} data={locations || []} getRowId={getRowId} />
  )
}
