import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import ParcelCard from '../common/ParcelCard'
import { theme } from '../theme'
import LegacyParcelsFilters from '../common/legacy/ParcelsFilters'

function LegacyParcelsView({ isModalOpen }) {
  const data = useSelector((state) => state.parcels.data)
  const carrier = useSelector((state) => state.root.carrier)
  // const parcels = useSelector((state) => state.root.parcels)
  const parcelFlow = useSelector((state) => state.parcels.filters.flow)
  const { t } = useTranslation('parcels')

  const renderStatusRow = (statusName) => {
    const segmentedParcels = data.parcels.reduce((acc, parcel) => {
      let { currentStatus } = parcel

      const finishedStatuses = [
        'driver-picked-up',
        'collected',
        'cancelled',
        'deviation',
        'pickup-collected',
      ]
      if (finishedStatuses.includes(currentStatus)) {
        currentStatus = 'finished'
      }

      if (!acc[currentStatus]) {
        acc[currentStatus] = [parcel]
      } else {
        acc[currentStatus].push(parcel)
      }

      return acc
    }, {})

    // eslint-disable-next-line no-underscore-dangle
    const _parcels = segmentedParcels[statusName] || []

    return (
      <div
        style={{
          minHeight: '100%',
          padding: 5,
          width: '26%',
          backgroundColor: theme(parcelFlow).primary,
          textAlign: 'center',
          borderRadius: 5,
        }}
      >
        {t(`statuses.${statusName}`)} ({_parcels.length}):
        <br />
        {_parcels.map((parcel) => {
          return <ParcelCard key={parcel?._id} parcel={parcel} />
        })}
      </div>
    )
  }

  const renderDistance = () => {
    return (
      <div
        style={{ width: '1%', minHeight: '100%', backgroundColor: 'inherit' }}
      >
        &nbsp;
      </div>
    )
  }

  if (!carrier) return <p>{t('loading')}</p>

  return (
    <div>
      <LegacyParcelsFilters isModalOpen={isModalOpen} />

      <div
        style={{
          borderRadius: 10,
          minHeight: '85vh',
          padding: 10,
          backgroundColor: '#f1f1f1f1',
          display: 'flex',
        }}
      >
        {carrier.parcelsViewColumns ? (
          <>
            {carrier.parcelsViewColumns.map((columnName) => {
              return (
                <>
                  {renderStatusRow(columnName)}
                  {renderDistance()}
                </>
              )
            })}
          </>
        ) : (
          <>
            {parcelFlow === 'delivery' ? (
              <>
                {renderStatusRow('created')}
                {renderDistance()}
                {renderStatusRow('on-way')}
                {renderDistance()}
                {renderStatusRow('in-box')}
                {renderDistance()}
                {renderStatusRow('marked-for-return')}
                {renderDistance()}
                {renderStatusRow('finished')}
              </>
            ) : (
              <>
                {renderStatusRow('created')}
                {renderDistance()}
                {renderStatusRow('pickup-on-way')}
                {renderDistance()}
                {renderStatusRow('pickup-in-box')}
                {renderDistance()}
                {renderStatusRow('pickup-ready-to-collect')}
                {renderDistance()}
                {renderStatusRow('finished')}
              </>
            )}
          </>
        )}
      </div>
    </div>
  )
}

export default LegacyParcelsView
